<script setup>
import Gallery from '@/views/devices/Gallery.vue'
import LastData from '@/views/devices/LastData.vue'
import UVCard from '@/views/devices/UVCard.vue'
import HumidityCard from '@/views/devices/HumidityCard.vue'
import WindCard from '@/views/devices/WindCard.vue'
import WindDirectionCard from '@/views/devices/WindDirectionCard.vue'
import WeeklyCards from '@/views/devices/WeeklyCards.vue'
import TemperatureCard from '@/views/devices/TemperatureCard.vue'
import LevelCard from '@/views/devices/LevelCard.vue'
import WaterTemperatureCard from '@/views/devices/WaterTemperatureCard.vue'
import RedoxCard from '@/views/devices/RedoxCard.vue'
import OxygenSatCard from '@/views/devices/OxygenSatCard.vue'
import OxygenMglCard from '@/views/devices/OxygenMglCard.vue'
import OxygenPpmCard from '@/views/devices/OxygenPpmCard.vue'
import PHCard from '@/views/devices/PHCard.vue'
import ConductivityCard from '@/views/devices/ConductivityCard.vue'
import SalinityCard from '@/views/devices/SalinityCard.vue'
import TdsKciCard from '@/views/devices/TdsKciCard.vue'
import IndexAirQualityCard from '@/views/devices/IndexAirQualityCard.vue'
import IndexWaterQualityCard from '@/views/devices/IndexWaterQualityCard.vue'
import Warnings from '@/views/devices/Warnings.vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()
const route = useRoute()

const uuid = route.params.uuid

const device = ref(null)
const channels = ref([])
const canManage = ref(false)

const prediction = ref(null)

const UVChannel = ref(null)
const TemperatureChannel = ref(null)
const HumidityChannel = ref(null)
const WindSpeedChannel = ref(null)
const WindDirectionChannel = ref(null)
const WindGustChannel = ref(null)
const BatteryChannel = ref(null)
const LevelChannel = ref(null)
const WaterTemperatureChannel = ref(null)
const PHChannel = ref(null)
const RedoxChannel = ref(null)
const OxygenSatChannel = ref(null)
const OxygenMglChannel = ref(null)
const OxygenPpmChannel = ref(null)
const ConductivityChannel = ref(null)
const SalinityChannel = ref(null)
const TdsKciChannel = ref(null)

const AirQualityIndexChannel = ref(null)
const WaterQualityIndexChannel = ref(null)

const updateData = () => {
  axios.get('/devices/' + uuid).then(response => {
    device.value = response.data
    canManage.value = response.data.can_manage
  }).catch(e => {
    console.error(e)
  })
}

const getLastData = () => {
  axios.get('/devices/' + uuid + '/last').then(response => {
    channels.value = response.data.data

    UVChannel.value = channels.value.find(obj => obj.tag === 'uv')
    TemperatureChannel.value = channels.value.find(obj => obj.tag === 'temperature')
    HumidityChannel.value = channels.value.find(obj => obj.tag === 'humidity')
    WindSpeedChannel.value = channels.value.find(obj => obj.tag === 'windspeed')
    WindDirectionChannel.value = channels.value.find(obj => obj.tag === 'winddirection')
    WindGustChannel.value = channels.value.find(obj => obj.tag === 'windgust')
    BatteryChannel.value = channels.value.find(obj => obj.tag === 'battery')
    LevelChannel.value = channels.value.find(obj => obj.tag === 'level')
    WaterTemperatureChannel.value = channels.value.find(obj => obj.tag === 'watertemperature')
    PHChannel.value = channels.value.find(obj => obj.tag === 'ph')
    RedoxChannel.value = channels.value.find(obj => obj.tag === 'redox')
    OxygenSatChannel.value = channels.value.find(obj => obj.tag === 'oxygensat')
    OxygenMglChannel.value = channels.value.find(obj => obj.tag === 'oxygenmgl')
    OxygenPpmChannel.value = channels.value.find(obj => obj.tag === 'oxygenppm')
    ConductivityChannel.value = channels.value.find(obj => obj.tag === 'conductivity')
    SalinityChannel.value = channels.value.find(obj => obj.tag === 'salinity')
    TdsKciChannel.value = channels.value.find(obj => obj.tag === 'tdskci')

    AirQualityIndexChannel.value = channels.value.find(obj => obj.tag === 'air_quality')
    WaterQualityIndexChannel.value = channels.value.find(obj => obj.tag === 'water_quality')
  }).catch(e => {
    console.error(e)
  })
}

const getPrediction = () => {
  axios.get('/devices/' + uuid + '/prediction').then(response => {
    prediction.value = response.data
  }).catch(e => {
    console.error(e)
  })
}

updateData()
getLastData()
getPrediction()
</script>

<template>
  <div>
    <VRow
      class="mb-6"
    >
      <VCol
        cols="12"
        md="3"
      >
        <TemperatureCard
          :device="device"
          :prediction="prediction"
          :battery="BatteryChannel"
          :temperature="TemperatureChannel"
          :can-manage="canManage"
          @update-data="updateData"
        />
      </VCol>
      <VCol
        cols="12"
        md="9"
      >
        <WeeklyCards :prediction="prediction" />
        <VRow v-if="device">
          <VCol
            v-if="WindSpeedChannel"
            xs6
          >
            <WindCard
              class="fill-height"
              :speed="WindSpeedChannel"
              :direction="WindDirectionChannel"
              :gust="WindGustChannel"
            />
          </VCol>
          <VCol
            v-if="UVChannel"
            xs6
          >
            <UVCard
              class="fill-height"
              :uv="UVChannel"
            />
          </VCol>
          <VCol
            v-if="HumidityChannel"
            xs6
          >
            <HumidityCard
              class="fill-height"
              :humidity="HumidityChannel"
            />
          </VCol>
          <VCol
            v-if="WaterTemperatureChannel"
            xs6
          >
            <WaterTemperatureCard
              class="fill-height"
              :water-temperature="WaterTemperatureChannel"
              :device="device"
            />
          </VCol>
          <VCol
            v-if="PHChannel"
            xs6
          >
            <PHCard
              class="fill-height"
              :ph="PHChannel"
              :device="device"
            />
          </VCol>
          <VCol
            v-if="RedoxChannel"
            xs6
          >
            <RedoxCard
              class="fill-height"
              :redox="RedoxChannel"
              :device="device"
            />
          </VCol>
          <VCol
            v-if="LevelChannel"
            xs6
          >
            <LevelCard
              class="fill-height"
              :level="LevelChannel"
            />
          </VCol>
          <VCol
            v-if="OxygenSatChannel"
            xs6
          >
            <OxygenSatCard
              class="fill-height"
              :oxygen="OxygenSatChannel"
              :device="device"
            />
          </VCol>
          <!--VCol
            v-if="OxygenMglChannel"
            xs6
          >
            <OxygenMglCard
              class="fill-height"
              :oxygen="OxygenMglChannel"
            />
          </VCol>
          <VCol
            v-if="OxygenPpmChannel"
            xs6
          >
            <OxygenPpmCard
              class="fill-height"
              :oxygen="OxygenPpmChannel"
            />
          </VCol-->
          <VCol
            v-if="ConductivityChannel"
            xs6
          >
            <ConductivityCard
              class="fill-height"
              :conductivity="ConductivityChannel"
              :device="device"
            />
          </VCol>
          <VCol
            v-if="SalinityChannel"
            xs6
          >
            <SalinityCard
              class="fill-height"
              :salinity="SalinityChannel"
              :device="device"
            />
          </VCol>
          <VCol
            v-if="TdsKciChannel"
            xs6
          >
            <TdsKciCard
              class="fill-height"
              :channel="TdsKciChannel"
              :device="device"
            />
          </VCol>
          <VCol
            v-if="AirQualityIndexChannel"
            xs6
          >
            <IndexAirQualityCard
              class="fill-height"
              :channel="AirQualityIndexChannel"
              :device="device"
            />
          </VCol>
          <VCol
            v-if="WaterQualityIndexChannel"
            xs6
          >
            <IndexWaterQualityCard
              class="fill-height"
              :channel="WaterQualityIndexChannel"
              :device="device"
            />
          </VCol>
        </VRow>
        <LastData
          class="mt-6"
          :device="device"
          :channels="channels"
        />
        <Gallery
          v-if="device && device.images.length"
          class="mt-6"
          :images="device.images"
        />
        <Warnings
          v-if="device && device.view_warnings"
          class="mt-6"
          :device="device"
        />
      </VCol>
    </VRow>
  </div>
</template>
