<script setup>
import axios from '@/helpers/axios'
import Footer from '@/views/front-pages/front-page-footer.vue'
import Navbar from '@/views/front-pages/front-page-navbar.vue'
import AppSearchHeaderBg from '@images/pages/app-search-header-bg.png'
import { LControlLayers, LMap, LMarker, LPopup, LTileLayer } from "@vue-leaflet/vue-leaflet"
import { useTitle } from '@vueuse/core'
import "leaflet/dist/leaflet.css"
import { useI18n } from 'vue-i18n'

definePage({
  meta: {
    layout: 'blank',
    public: true
  }
})

const router = useRouter()
const { t } = useI18n()

useTitle(t('landing.webcams') + ' - Kropie')

const webcams = ref(null)
const locations = ref([])

const openPopup = ref(null)

const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlOutdoors = ref('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const center = ref([40, -7.746377])
const zoom = ref(7)


const updateData = () => {
  axios.get('/landing/webcams').then(response => {
    webcams.value = response.data.data

  }).catch(e => {
    console.error(e)
  })
}

updateData()

const bounds = [
  [36.9612, 9.5266], // [South Latitude, West Longitude]
  [42.1544, 6.1896], // [North Latitude, East Longitude]
];

/*
const bounds = computed(() => {
  if (webcams.value === null) {
    return null
  }

  webcams.value.forEach(webcam => {
    locations.value.push([webcam.latitude, webcam.longitude])
  })

  const bounds =  L.latLngBounds(locations.value)
  
  return [
    [bounds._southWest.lat, bounds._southWest.lng],
    [bounds._northEast.lat, bounds._northEast.lng],
  ]
})
*/

const setPopupOpen = (camera_id) => {
  openPopup.value = camera_id
  axios.get('/landing/webcams/' + camera_id + '/track')
}

</script>

<template>

<div class="help-center-page">
  <Navbar />
        <div class="content-wrapper">
            <VCard
                flat
                class="text-center search-header"
                :style="`background: url(${AppSearchHeaderBg});`"
            >
                <VCardText>
                  <h3 class="text-h3 font-weight-medium py-10">
                    {{ $t('landing.webcams') }}
                  </h3>

                  <p class="text-disabled mb-6">
                    {{ $t('landing.webcams_description') }}
                  </p>
                </VCardText>
            </VCard>


            <div class="py-16 bg-surface">
                <VContainer>
                  <LMap
                    ref="map"
                    :options="{scrollWheelZoom:false}"
                    :center="center"
                    :zoom="zoom"
                    :bounds="bounds"
                    :padding="[50, 50]"
                    :use-global-leaflet="false"
                    style="height: 80vh;"
                  >
                    <LTileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      layer-type="base"
                      name="OpenStreetMap"
                      :visible="false"
                    />
                    <LTileLayer
                      :url="urlSattelite"
                      layer-type="base"
                      name="Mapbox Satellite"
                      :visible="false"
                    />
                    <LTileLayer
                      :url="urlOutdoors"
                      layer-type="base"
                      name="Mapbox Outdoors"
                      visible
                    />
                    <LControlLayers
                      :collapsed="false"
                      position="topright"
                    />
                    <LMarker
                      v-for="item in webcams"
                      :key="item.camera_id"
                      :lat-lng="[item.latitude, item.longitude]"
                      :name="item.location.city + ' - ' + item.location.country"
                      @click="setPopupOpen(item.camera_id)"
                    >
                      <LPopup>
                        <RouterLink
                          :to="{name: 'webcams-id', params: {id: item.camera_id}}"
                        >
                          {{ item.location.city + ' - ' + item.location.country }}
                        </RouterLink>
                        <iframe v-if="openPopup == item.camera_id" :src="item.player.day"></iframe>
                        <VBtn
                          class="mt-3"
                          type="button"
                          :to="{name: 'webcams-id', params: {id: item.camera_id}}"
                        >
                          {{ $t('landing.open_webcam') }}
                        </VBtn>
                      </LPopup>
                    </LMarker>
                  </LMap>
                </VContainer>
            </div>
        </div>

        <Footer />
    </div>
</template>

<style lang="scss">
.help-center-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content-wrapper {
    flex: 1; // This makes the content expand to fill the available space
  }

  .search-header {
    background-size: cover !important;
    padding-block-start: 7rem !important;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .help-center-page {
    .v-container {
      padding-inline: 2rem !important;
    }

    .search-header {
      padding: 5rem !important;
    }
  }
}

@media (max-width: 599px) {
  .help-center-page {
    .search-header {
      padding-block-end: 2rem !important;
      padding-block-start: 4rem !important;
      padding-inline: 2rem !important;
    }
  }
}
</style>