<script setup>
import { useI18n } from 'vue-i18n'
import CreatePostDrawer from '@/views/blog/CreatePostDrawer.vue'
import axios from '@/helpers/axios'
import moment from "moment";

definePage({
  meta: {
    layout: 'default',
    role: 'marketing'
  },
})

const { t } = useI18n()

const searchQuery = ref('')
const rowPerPage = ref(10)
const currentPage = ref(1)
const totalPage = ref(1)
const totalItems = ref(0)
const items = ref([])

const showCreatePostDrawer = ref(false)

const getPosts = () => {
  axios.get('/marketing/blog', {
    params: {
      q: searchQuery.value,
      perPage: rowPerPage.value,
      currentPage: currentPage.value,
    },
  }).then(response => {
    items.value = response.data.data
    totalPage.value = response.data.meta.last_page
    totalItems.value = response.data.meta.total
  }).catch(e => {
    console.error(e)
  })
}

watchEffect(() => {
  getPosts()
})

watchEffect(() => {
  if (currentPage.value > totalPage.value)
    currentPage.value = totalPage.value
})


// 👉 Computing pagination data
const paginationData = computed(() => {
  const firstIndex = items.value.length ? (currentPage.value - 1) * rowPerPage.value + 1 : 0
  const lastIndex = items.value.length + (currentPage.value - 1) * rowPerPage.value
  
  return t('tables.showing', { firstIndex, lastIndex, totalItems: totalItems.value })
})

const resolveStatusColor = stat => {
  if (stat === false)
    return 'warning'
  
  return 'primary'
}

const resolveStatusText = stat => {
  if (stat === false)
    return t('maps.pending')
  
  return t('maps.processed')
}
</script>

<template>
  <div>
    <VCard
      v-if="items"
      id="items-list"
      :title="$t('blog.blog') + ' 📝'"
    >
      <VCardText class="d-flex align-center flex-wrap gap-4">
        <!-- 👉 Rows per page -->
        <div
          class="d-flex align-center"
          style="width: 150px;"
        >
          <span class="text-no-wrap me-3">{{ $t('tables.show') }}</span>
          <VSelect
            v-model="rowPerPage"
            density="compact"
            :items="[10, 20, 30, 50]"
          />
        </div>

        <VSpacer />

        <div class="d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div class="items-list-filter">
            <VTextField
              v-model="searchQuery"
              :placeholder="$t('blog.search_post')"
              density="compact"
            />
          </div>

          <VBtn
            prepend-icon="tabler-plus"
            @click="showCreatePostDrawer = true"
          >
            {{ $t('blog.add_post') }}
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION Table -->
      <VTable class="text-no-wrap items-list-table">
        <!-- 👉 Table head -->
        <thead class="text-uppercase">
          <tr>
            <th scope="col">
              {{ $t('blog.title') }}
            </th>

            <th scope="col">
              {{ $t('blog.slug') }}
            </th>

            <th scope="col">
              {{ $t('blog.date') }}
            </th>

            <th scope="col" />
          </tr>
        </thead>

        <!-- 👉 Table Body -->
        <tbody>
          <tr
            v-for="post in items"
            :key="post.uuid"
            style="height: 3.75rem;"
          >
            <!-- 👉 Title -->
            <td>
              <RouterLink :to="{ name: 'marketing-blog-slug', params: { slug: post.slug } }">
                {{ post.title }}
              </RouterLink>
            </td>

            <!-- 👉 Slug -->
            <td>
              {{ post.slug }}
            </td>

            <!-- 👉 Date -->
            <td>
              {{ moment.utc(post.created_at).local().format('Y-MM-DD HH:mm') }}
            </td>

            <!-- 👉 Actions -->
            <td style="width: 8rem;">
              <VBtn
                icon
                variant="text"
                color="default"
                size="x-small"
                :to="{ name: 'blog-slug', params: { slug: post.slug } }"
              >
                <VIcon
                  :size="22"
                  icon="tabler-eye"
                />
              </VBtn>
            </td>
          </tr>
        </tbody>

        <!-- 👉 table footer  -->
        <tfoot v-show="!items.length">
          <tr>
            <td
              colspan="8"
              class="text-center text-body-1"
            >
              {{ $t('blog.no_posts') }}
            </td>
          </tr>
        </tfoot>
      </VTable>
      <!-- !SECTION -->

      <VDivider />

      <!-- SECTION Pagination -->
      <VCardText class="d-flex align-center flex-wrap gap-4 py-3">
        <!-- 👉 Pagination meta -->
        <span class="text-sm text-disabled">
          {{ paginationData }}
        </span>

        <VSpacer />

        <!-- 👉 Pagination -->
        <VPagination
          v-model="currentPage"
          size="small"
          :total-visible="5"
          :length="totalPage"
        />
      </VCardText>
      <!-- !SECTION -->
    </VCard>
    <CreatePostDrawer
      :is-drawer-open="showCreatePostDrawer"
      @update:is-drawer-open="(val) => showCreatePostDrawer = val"
      @submit="getPosts"
    />
  </div>
</template>

<style lang="scss">
#items-list {
  .items-list-actions {
    inline-size: 8rem;
  }

  .items-list-filter {
    inline-size: 12rem;
  }
}
</style>
