<script setup>
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import moment from "moment";
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'marketing'
  },
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const slug = route.params.slug
const post = ref(null)
const loading = ref(false)

const updateData = () => {
  axios.get('/blog/' + slug).then(response => {
    post.value = response.data
  }).catch(e => {
    console.error(e)
  })
}

const save = () => {
  loading.value = true
  axios.post('/marketing/blog/' + slug, {
    title: post.value.title,
    content: post.value.content,
  }).then(response => {

    router.replace({ name: 'marketing-blog' })

    return

  }).catch(e => {
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
    console.error(e)
  }).finally(() => {
    loading.value = false
  })
    
}

updateData()
</script>

<template>
  <div>
    <VCard
      v-if="post !== null"
      :title="post.title"
      :subtitle="moment.utc(post.created_at).local().format('Y-MM-DD HH:mm')"
      class="mb-6"
    >
      <VCardText>
        <QuillEditor
          v-model:content="post.content"
          theme="snow"
          toolbar="full"
          content-type="html"
        />
      </VCardText>
      <VCardActions>
        <VBtn
          :loading="loading"
          :disabled="loading"
          @click="save"
        >
          {{ $t('layout.save_changes') }}
        </VBtn>
      </VCardActions>
    </VCard>
  </div>
</template>
