<script setup>
import CreateObservationDrawer from '@/views/observations/CreateObservationDrawer.vue'
import MapDialog from '@/views/observations/MapDialog.vue'
import axios from '@/helpers/axios'
import { useI18n } from 'vue-i18n'
import debounce from 'lodash/debounce'
import moment from "moment";

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()


const observations = ref([])
const selfUser = ref(useCookie('userData').value)
const showCreateObservationDrawer = ref(false)
const showMapDialog = ref(false)
const searchQuery = ref('')
const selectedType = ref(null)
const orderBy = ref('occured_at')
const sortDirectionDesc = ref(true)
const rowPerPage = ref(6)
const currentPage = ref(1)
const totalPage = ref(1)
const totalItems = ref(0)

const observationTypes = ref([])

const isLoadingMore = ref(false)


const getTypes = () => {
  axios.get('/observation/types').then(response => {
    const items = []

    response.data.data.forEach(element => {
      items.push({
        slug: element.slug,
        name: t('observations.types.' + element.slug)
      })
    });

    observationTypes.value = items
  }).catch(e => {
    console.error(e)
  })
}

getTypes()

const getObservations = (isLoadMore = false) => {
  isLoadingMore.value = true
  axios.get('/observation/mine', {
    params: {
      q: searchQuery.value,
      perPage: rowPerPage.value,
      currentPage: currentPage.value,
      type: selectedType.value,
      order: orderBy.value,
      sort: sortDirectionDesc.value === true ? 'desc' : 'asc'
    },
  }).then(response => {
    if (isLoadMore) {
      observations.value = [...observations.value, ...response.data.data]
    } else {
      observations.value = response.data.data
    }
    totalPage.value = response.data.meta.last_page
    totalItems.value = response.data.meta.total
  }).catch(e => {
    console.error(e)
  }).finally(() => {
    isLoadingMore.value = false
  })
}

getObservations()

const loadMore = () => {
  if (currentPage.value < totalPage.value) {
    currentPage.value += 1
    getObservations(true)
  }
}

// Debounced version of getObservations
const debouncedGetObservations = debounce(getObservations, 300)

// Watch filters and search query with debounce for search input
watch([searchQuery, selectedType, orderBy, sortDirectionDesc], () => {
  currentPage.value = 1
  debouncedGetObservations()
})
</script>

<template>
  <div>
    <VCard
      :title="$t('observations.my_observations')"
      class="mb-6"
    >
      <VCardText>{{ $t('observations.description') }}</VCardText>
      <VCardActions>
        <VBtn
          @click="showCreateObservationDrawer = true"
        >
          <VIcon
            start
            icon="tabler-plus"
          />
          {{ $t('observations.submit_observation') }}
        </VBtn>
        <VBtn
          @click="showMapDialog = true"
        >
          <VIcon
            start
            icon="tabler-map"
          />
          {{ $t('observations.view_map') }}
        </VBtn>
      </VCardActions>
    </VCard>

    <VRow class="mb-4" align="center" justify="space-between">
        <!-- Search Field -->
        <VCol cols="12" md="3">
          <VTextField
            v-model="searchQuery"
            :label="$t('layout.search')"
            prepend-inner-icon="tabler-search"
            clearable
            class="pa-2"
            @input="getObservations"
          />
        </VCol>

        <!-- Type Filter -->
        <VCol cols="12" md="3">
          <VSelect
            v-model="selectedType"
            :items="observationTypes"
            item-title="name"
            item-value="slug"
            :label="$t('observations.type')"
            prepend-inner-icon="tabler-filter"
            clearable
            class="pa-2"
            @change="getObservations"
          />
        </VCol>

        <!-- Order By Selection -->
        <VCol cols="12" md="3">
          <VSelect
            v-model="orderBy"
            :items="[
              { value: 'occured_at', label: $t('observations.order_by_date') },
              { value: 'title', label: $t('observations.order_by_title') },
              { value: 'views', label: $t('observations.order_by_views') }
            ]"
            item-title="label"
            item-value="value"
            :label="$t('layout.order_by')"
            prepend-inner-icon="tabler-arrows-sort"
            class="pa-2"
            @change="getObservations"
          />
        </VCol>

        <!-- Sort Direction Switch -->
        <VCol cols="12" md="3">
          <VSwitch
            v-model="sortDirectionDesc"
            :label="sortDirectionDesc === true ? $t('layout.desc') : $t('layout.asc')"
            class="pa-2"
            inset
            @change="getObservations"
          />
        </VCol>
      </VRow>

    <VRow class="match-height">
      <VCol
        v-for="observation in observations"
        :key="observation.uuid"
        cols="12"
        sm="6"
        md="4"
      >
        <RouterLink :to="{ name: 'observations-uuid', params: { uuid: observation.uuid } }">
          <VCard>
            <VCardTitle>
              <div class="d-flex justify-space-between flex-wrap flex-column flex-sm-row mt-2">
                <div class="d-flex align-center mt-2 mt-sm-0" style="display: flex; max-width: 80%; overflow: hidden;">
                    <span class="text-h5 text-truncate" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                        {{ observation.title }}
                    </span>
                </div>

                <div class="d-flex align-center mt-2 mt-sm-0">
                  <VChip color="primary">
                    {{ $t('observations.types.' + observation.type.slug) }}
                  </VChip>
                </div>
              </div>
            </VCardTitle>

            <VCardText>
              <div class="d-flex align-center mt-2 mt-sm-0">
                <VIcon icon="tabler-map-pin" class="me-1" />
                <span class="text-subtitle-1">{{ observation.country.name }} <span v-if="observation.city">- {{ observation.city.name }}</span></span>
              </div>
              <div class="d-flex align-center mt-2 mt-sm-0">
                <VIcon icon="tabler-calendar" class="me-1" />
                <span class="text-subtitle-1">{{ moment.utc(observation.occured_at).local().format('Y-MM-DD') }}</span>
              </div>
            </VCardText>
              
            <VCardText>
              <div class="d-flex align-center">
                <VAvatar v-for="media in observation.media" :image="media.thumb_url" rounded="lg" size="x-large" class="mr-2" />
              </div>
            </VCardText>

            <VCardText class="d-flex justify-space-between align-center flex-wrap pb-4">
              <div class="d-flex align-center">
                <VAvatar size="34" :image="observation.user.avatar_thumbnail" />
                <span class="ms-2 font-weight-medium">{{ observation.user.name }}</span>
              </div>

              <div class="d-flex align-center">
                <IconBtn icon="tabler-eye" class="me-1" />
                <span class="text-subtitle-2 mt-1">{{ observation.views }}</span>
              </div>
            </VCardText>
          </VCard>
        </RouterLink>

      </VCol>
    </VRow>
    <!-- Load More Button -->
    <VRow justify="center" class="mt-4">
      <VBtn
        v-if="currentPage < totalPage"
        @click="loadMore"
        :loading="isLoadingMore"
        :disabled="isLoadingMore"
      >
        {{ $t('layout.load_more') }}
      </VBtn>
    </VRow>

    <CreateObservationDrawer
      :is-drawer-open="showCreateObservationDrawer"
      @update:is-drawer-open="(val) => showCreateObservationDrawer = val"
      @submit="getObservations"
    />

    <MapDialog
      :is-dialog-open="showMapDialog"
      @update:is-dialog-open="(val) => showMapDialog = val"
    />
  </div>
</template>
