<script setup>
import axios from '@/helpers/axios'
import Statistics from '@/views/dashboard/Statistics.vue'
import Devices from '@/views/dashboard/Devices.vue'
import Maps from '@/views/dashboard/Maps.vue'
import Notebooks from '@/views/dashboard/Notebooks.vue'
import Credits from '@/views/dashboard/Credits.vue'
import Favorites from '@/views/dashboard/Favorites.vue'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const data = ref({
  count: {},
  notebooks: [],
  devices: [],
  maps: [],
})

axios.get('/dashboard').then(r => {
  data.value = r.data
}).catch(e => {
  console.error(e)
})

</script>

<template>
  <div>
    <VRow class="match-height mb-4">
      <VCol
        cols="12"
        md="3"
        sm="4"
      >
        <Credits />
      </VCol>

      <VCol
        cols="12"
        md="9"
        sm="8"
      >
        <Statistics
          class="mb-6"
          :count="data.count"
        />
      </VCol>
    </VRow>

    <VRow class="match-height mb-6">
      <VCol
        cols="12"
        md="4"
        sm="6"
      >
        <Devices :devices="data.devices" />
      </VCol>
      <!--VCol
        cols="12"
        md="4"
        sm="6"
      >
        <Maps :maps="data.maps" />
      </VCol-->
      <VCol
        cols="12"
        md="4"
        sm="6"
      >
        <Notebooks :notebooks="data.notebooks" />
      </VCol>
      <VCol
        cols="12"
        md="4"
        sm="6"
      >
        <Favorites />
      </VCol>
    </VRow>
  </div>
</template>
