<script setup>
import CreateLayerDrawer from '@/views/maps/CreateLayerDrawer.vue'
import EditMapDrawer from '@/views/maps/EditMapDrawer.vue'
import AddGeoJsonDrawer from '@/views/maps/AddGeoJsonDrawer.vue'
import AddFileDrawer from '@/views/maps/AddFileDrawer.vue'
import FilesList from '@/views/maps/FilesList.vue'
import DeleteGeojsonDialog from '@/views/general/DeleteDialog.vue'
import DeleteFileDialog from '@/views/general/DeleteDialog.vue'
import { LControlLayers, LGeoJson, LMap, LTileLayer } from "@vue-leaflet/vue-leaflet"
import "leaflet/dist/leaflet.css"
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import axios from '@/helpers/axios'
import moment from "moment";
import GeneralDialog from '@/views/general/GeneralDialog.vue'
import Loader from '@/views/Loader.vue'

definePage({
  meta: {
    layout: 'default',
    role: 'admin'
  },
})

const showLogsDialog = ref(false)
const selectedLayer = ref(null)
const showEditMapDrawer = ref(false)
const showCreateLayerDrawer = ref(false)
const showAddGeoJsonDrawer = ref(false)
const showFileDrawer = ref(false)
const showDeleteGeojsonDialog = ref(false)
const showDeleteFileDialog = ref(false)

const { t } = useI18n()
const route = useRoute()
const uuid = route.params.uuid
const map = ref(null)
const zoom = ref(17)
const center = ref([0, 0])
const bounds = ref(null)
const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlOutdoors = ref('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)

const openLog = (layer) => {
  selectedLayer.value = layer
  showLogsDialog.value = true
}

const resolveBoolVariant = (data) => {
  if (data)
    return { color: 'success', text: t('layout.yes') }

  return { color: 'error', text: t('layout.no') }
}

const mapRef = ref()

const geojsonStyle = ref({
  //weight: 2,
  //color: "#ECEFF1",
  //opacity: 1,
  //fillColor: "#3388FF",
  fillOpacity: 0
})

function onMapReady(map) {
  mapRef.value = map
}

const changeLayerVisibility = (layer, visibility) => {
  axios.patch('/admin/maps/' + uuid + '/' + layer, {
    visible: visibility,
  }).then(response => {
    toast.success(t('maps.layer_updated'), {
      autoClose: 3000,
    })
    updateData()

    return
  }).catch(e => {
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
    console.error(e)
  })
}

const deleteLayer = layer => {
  axios.delete('/admin/maps/' + uuid + '/' + layer).then(response => {
    toast.success(t('maps.layer_deleted'), {
      autoClose: 3000,
    })
    updateData()

    return
  }).catch(e => {
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
    console.error(e)
  })
}

const deleteGeojson = () => {
  axios.delete('/admin/maps/' + uuid + '/geojson').then(response => {
    toast.success(t('maps.geojson_deleted'), {
      autoClose: 3000,
    })
    updateData()
    showDeleteGeojsonDialog.value = false
  }).catch(e => {
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
    console.error(e)
  })
}

const updateData = () => {
  axios.get('/admin/maps/' + uuid).then(response => {
    map.value = response.data
    center.value = response.data.bounds.center.reverse()
    bounds.value = [response.data.bounds.lowerLeft.reverse(), response.data.bounds.upperRight.reverse()]
  }).catch(e => {
    console.error(e)
  })
}

updateData()
</script>

<template>
  <div>
    <Loader
      v-if="!map"
      class="mt-4"
    />
    <VCard
      v-else
      :title="map.name"
      :subtitle="map.public === true ? $t('maps.public') : $t('maps.private')"
      class="mb-6"
    >
      <VCardText>
        <VChip
          v-for="layer in map.layers"
          :key="layer.uuid"
          variant="elevated"
          class="mr-2"
        >
          {{ layer.name }}
        </VChip>
      </VCardText>
      <VCardText
        v-if="map.notes"
      >
        {{ map.notes }}
      </VCardText>
      <VCardText>
        <VRow>
          <VCol
            cols="12"
            order="2"
            order-sm="1"
          >
            <VRow>
              <VCol
                v-if="map.area"
                cols="12"
                lg="6"
                sm="3"
                class="text-no-wrap"
              >
                <span class="me-2">{{ $t('maps.area') }}</span>

                <VChip
                  label
                  color="primary"
                >
                  <span v-html="map.area + ' m<sup>2</sup>'" />
                </VChip>
              </VCol>
              <VCol
                cols="12"
                lg="6"
                sm="3"
                class="text-no-wrap"
              >
                <span class="me-2">{{ $t('layout.visibility') }}</span>

                <VChip
                  label
                  :color="map.public ? 'warning' : 'primary'"
                >
                  {{ map.public ? $t('maps.public') : $t('maps.private') }}
                </VChip>
              </VCol>
              <VCol
                cols="12"
                lg="6"
                sm="3"
                class="text-no-wrap"
              >
                <span class="me-2">{{ $t('maps.geojson') }}</span>

                <VChip
                  label
                  :color="map.geojson ? 'primary' : 'warning'"
                >
                  {{ map.geojson ? $t('layout.yes') : $t('layout.no') }}
                </VChip>
                <VBtn
                  v-if="map.geojson"
                  icon="tabler-trash"
                  variant="text"
                  color="secondary"
                  @click="showDeleteGeojsonDialog = true"
                />
              </VCol>
              <VCol
                v-for="data in map.extra_data"
                :key="data.field"
                cols="12"
                lg="6"
                sm="3"
                class="text-no-wrap"
              >
                <span class="me-2">{{ data.field }}</span>

                <VChip
                  label
                  color="primary"
                >
                  {{ data.value }}
                </VChip>
              </VCol>
              <VCol
                v-if="map.attachments && map.attachments.length"
                cols="12"
                class="text-no-wrap"
              >
                <span class="me-2">{{ $t('maps.attachments') }}</span>
                <FilesList
                  :files="map.attachments"
                  :uuid="uuid"
                  @update="updateData"
                />
                  
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCardText>
      <VCardActions>
        <VRow>
          <VCol :cols="12">
            <VBtn
              @click="showCreateLayerDrawer = true"
            >
              <VIcon
                start
                icon="tabler-plus"
              />
              {{ $t('maps.add_layer') }}
            </VBtn>
            <VBtn
              @click="showEditMapDrawer = true"
            >
              <VIcon
                start
                icon="tabler-pencil"
              />
              {{ $t('maps.edit_map') }}
            </VBtn>
            <VBtn
              v-if="!map.file"
              @click="showFileDrawer = true"
            >
              <VIcon
                start
                icon="tabler-folder"
              />
              {{ $t('maps.add_attachment') }}
            </VBtn>
            <VBtn
              v-if="!map.geojson"
              @click="showAddGeoJsonDrawer = true"
            >
              <VIcon
                start
                icon="tabler-map"
              />
              {{ $t('maps.add_geojson') }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <VCard
      v-if="map"
      :title="$t('maps.layers')"
      class="mb-6"
    >
      <VCardText>
        <VTable class="text-no-wrap items-list-table">
          <!-- 👉 Table head -->
          <thead class="text-uppercase">
            <tr>
              <th scope="col">
                {{ $t('maps.name') }}
              </th>

              <th scope="col">
                {{ $t('maps.visible') }}
              </th>

              <th scope="col">
                {{ $t('maps.processed') }}
              </th>

              <th scope="col">
                {{ $t('maps.date') }}
              </th>

              <th scope="col" />
            </tr>
          </thead>

          <!-- 👉 Table Body -->
          <tbody>
            <tr
              v-for="layer in map.layers"
              :key="layer.uuid"
              style="height: 3.75rem;"
            >
              <!-- 👉 Name -->
              <td>
                {{ layer.name }}
              </td>

              <!-- 👉 Visible -->
              <td>
                <VChip
                  :color="resolveBoolVariant(layer.visible).color"
                  class="font-weight-medium"
                  size="small"
                >
                  {{ resolveBoolVariant(layer.visible).text }}
                </VChip>
              </td>

              <!-- 👉 Processed -->
              <td>
                <VChip
                  :color="resolveBoolVariant(layer.processed).color"
                  class="font-weight-medium"
                  size="small"
                >
                  {{ resolveBoolVariant(layer.processed).text }}
                </VChip>
              </td>

              <!-- 👉 Date -->
              <td>
                {{ moment.utc(layer.created_at).local().format("Y-MM-DD HH:mm") }}
              </td>

              <!-- 👉 Actions -->
              <td style="width: 8rem;">
                <VBtn
                  v-if="layer.processing_output"
                  icon
                  variant="text"
                  color="default"
                  size="x-small"
                  @click="openLog(layer)"
                >
                  <VIcon
                    :size="22"
                    icon="tabler-article"
                  />
                </VBtn>
                <VBtn
                  v-if="!layer.visible"
                  icon
                  variant="text"
                  color="default"
                  size="x-small"
                  @click="changeLayerVisibility(layer.uuid, true)"
                >
                  <VIcon
                    :size="22"
                    icon="tabler-eye"
                  />
                </VBtn>
                <VBtn
                  v-if="layer.visible"
                  icon
                  variant="text"
                  color="default"
                  size="x-small"
                  @click="changeLayerVisibility(layer.uuid, false)"
                >
                  <VIcon
                    :size="22"
                    icon="tabler-eye-off"
                  />
                </VBtn>
                <VBtn
                  icon
                  variant="text"
                  color="default"
                  size="x-small"
                  @click="deleteLayer(layer.uuid)"
                >
                  <VIcon
                    :size="22"
                    icon="tabler-trash"
                  />
                </VBtn>
              </td>
            </tr>
          </tbody>

          <!-- 👉 table footer  -->
          <tfoot v-show="!map.layers.length">
            <tr>
              <td
                colspan="8"
                class="text-center text-body-1"
              >
                {{ $t('maps.no_layers') }}
              </td>
            </tr>
          </tfoot>
        </VTable>
      </VCardText>
    </VCard>
    <VCard
      v-if="map && map.layers && map.layers.length > 0"
      :title="$t('maps.map')"
    >
      <VCardText>
        <div
          style="height: 500px;"
        >
          <LMap
            v-model:zoom="zoom"
            :center="center"
            :max-bounds="bounds"
            :use-global-leaflet="false"
            @ready="onMapReady"
          >
            <LTileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
              :visible="false"
            />
            <LTileLayer
              :url="urlSattelite"
              layer-type="base"
              name="Mapbox Satellite"
              visible
            />
            <LTileLayer
              :url="urlOutdoors"
              layer-type="base"
              name="Mapbox Outdoors"
              :visible="false"
            />
            <LTileLayer
              v-for="(layer, index) in map.layers"
              :key="layer.uuid"
              :url="layer.url"
              layer-type="overlay"
              :options="{tms: 1, opacity: 1, attribution: '', minZoom: 2, maxZoom: 22}"
              :name="layer.name"
              :visible="index === 0"
            />
            <LGeoJson
              v-if="map.geojson"
              :geojson="map.geojson"
              :name="$t('maps.parcel')"
              layer-type="overlay"
              :options-style="geojsonStyle"
            />
            <LControlLayers
              :collapsed="false"
              position="topright"
            />
          </LMap>
        </div>
      </VCardText>
    </VCard>
    <CreateLayerDrawer
      :is-drawer-open="showCreateLayerDrawer"
      :map="map"
      @update:is-drawer-open="(val) => showCreateLayerDrawer = val"
      @submit="updateData"
    />
    <AddGeoJsonDrawer
      :is-drawer-open="showAddGeoJsonDrawer"
      :map="map"
      @update:is-drawer-open="(val) => showAddGeoJsonDrawer = val"
      @submit="updateData"
    />
    <DeleteGeojsonDialog
      :is-dialog-open="showDeleteGeojsonDialog"
      :title="$t('maps.delete_geojson')"
      :text="$t('maps.delete_geojson_confirm')"
      @update:is-dialog-open="(val) => showDeleteGeojsonDialog = val"
      @submit="deleteGeojson"
    />
    <AddFileDrawer
      :is-drawer-open="showFileDrawer"
      :map="map"
      @update:is-drawer-open="(val) => showFileDrawer = val"
      @submit="updateData"
    />
    <DeleteFileDialog
      :is-dialog-open="showDeleteFileDialog"
      :title="$t('maps.delete_file')"
      :text="$t('maps.delete_file_confirm')"
      @update:is-dialog-open="(val) => showDeleteFileDialog = val"
      @submit="deleteFile"
    />
    <GeneralDialog
      v-if="selectedLayer"
      :is-dialog-open="showLogsDialog"
      :title="$t('maps.logs')"
      :text="selectedLayer.processing_output"
      @update:is-dialog-open="(val) => showLogsDialog = val"
    />
    <EditMapDrawer
      v-if="map"
      :is-drawer-open="showEditMapDrawer"
      :map="map"
      @update:is-drawer-open="(val) => showEditMapDrawer = val"
      @submit="updateData"
    />
  </div>
</template>
