<script setup>
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg'
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const { t } = useI18n()

const refVForm = ref()
const name = ref('')
const organization = ref('')
const email = ref('')
const password = ref('')
const privacyPolicies = ref(true)
const isPasswordVisible = ref(false)

const route = useRoute()
const router = useRouter()

const errors = ref({
  email: undefined,
  password: undefined,
})


const register = () => {
  axios.post('/auth/register', {
    name: name.value,
    email: email.value,
    password: password.value,
    organization: organization.value,
  }).then(r => {

    if (r.data.result === false) {
      toast.error(t('layout.error'), {
        autoClose: 3000,
      })

      return
    }

    const { token, user } = r.data

    useCookie('userData').value = user
    useCookie('accessToken').value = token
    useCookie('userRoles').value = user.roles

    // Redirect to `to` query if exist or redirect to index route
    router.replace(route.query.to ? String(route.query.to) : '/dashboard')
    
    return null
  }).catch(e => {
    console.error(e)
  })
}

const onSubmit = () => {
  refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid)
      register()
  })
}
</script>

<template>
  <div class="auth-wrapper d-flex align-center justify-center pa-4">
    <div class="position-relative my-sm-16">
      <!-- 👉 Top shape -->
      <VImg
        :src="authV1TopShape"
        class="auth-v1-top-shape d-none d-sm-block"
      />

      <!-- 👉 Bottom shape -->
      <VImg
        :src="authV1BottomShape"
        class="auth-v1-bottom-shape d-none d-sm-block"
      />

      <!-- 👉 Auth card -->
      <VCard
        class="auth-card pa-4"
        max-width="448"
      >
        <VCardItem class="justify-center">
          <template #prepend>
            <div class="d-flex">
              <VNodeRenderer :nodes="themeConfig.app.logo" />
            </div>
          </template>

          <VCardTitle class="font-weight-bold text-h5 py-1">
            {{ themeConfig.app.title }}
          </VCardTitle>
        </VCardItem>

        <VCardText class="pt-2">
          <h5 class="text-h5 font-weight-semibold mb-1">
            {{ $t('auth.lets_start') }} 🚀
          </h5>
          <p class="mb-0">
            {{ $t('auth.register_description') }}
          </p>
        </VCardText>

        <VCardText>
          <VForm
            ref="refVForm"
            @submit.prevent="onSubmit"
          >
            <VRow>
              <!-- name -->
              <VCol cols="12">
                <VTextField
                  v-model="name"
                  :rules="[requiredValidator]"
                  :label="$t('auth.name')"
                  type="text"
                />
              </VCol>

              <!-- name -->
              <VCol cols="12">
                <VTextField
                  v-model="organization"
                  :rules="[]"
                  :label="$t('auth.organization')"
                  type="text"
                />
              </VCol>

              <!-- email -->
              <VCol cols="12">
                <VTextField
                  v-model="email"
                  :rules="[requiredValidator, emailValidator]"
                  :label="$t('auth.email')"
                  type="email"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <VTextField
                  v-model="password"
                  :label="$t('auth.password')"
                  :rules="[requiredValidator]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />

                <div class="d-flex align-center mt-2 mb-4">
                  <VCheckbox
                    id="privacy-policy"
                    v-model="privacyPolicies"
                    inline
                  />
                  <VLabel
                    for="privacy-policy"
                    style="opacity: 1;"
                  >
                    <span class="me-1">{{ $t('auth.agree') }}</span>
                    <a
                      href="javascript:void(0)"
                      class="text-primary"
                    >{{ $t('auth.privacy') }}</a>
                  </VLabel>
                </div>

                <VBtn
                  block
                  type="submit"
                >
                  {{ $t('auth.register') }}
                </VBtn>
              </VCol>

              <!-- login instead -->
              <VCol
                cols="12"
                class="text-center text-base"
              >
                <span>{{ $t('auth.already_have_account') }}</span>
                <RouterLink
                  class="text-primary ms-2"
                  :to="{ name: 'login' }"
                >
                  {{ $t('auth.login') }}
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </div>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
