<script setup>
import Footer from '@/views/front-pages/front-page-footer.vue'
import Navbar from '@/views/front-pages/front-page-navbar.vue'
import laptopGirl from '@images/illustrations/laptop-girl.png'
import { useTitle } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import axios from '@/helpers/axios'

import HumidityCard from '@/views/devices/HumidityCard.vue'
import TemperatureCard from '@/views/devices/TemperatureCard.vue'
import UVCard from '@/views/devices/UVCard.vue'
import WeeklyCards from '@/views/devices/WeeklyCards.vue'
import WindCard from '@/views/devices/WindCard.vue'

import LevelCard from '@/views/devices/LevelCard.vue'
import WaterTemperatureCard from '@/views/devices/WaterTemperatureCard.vue'
import RedoxCard from '@/views/devices/RedoxCard.vue'
import OxygenSatCard from '@/views/devices/OxygenSatCard.vue'
import OxygenMglCard from '@/views/devices/OxygenMglCard.vue'
import OxygenPpmCard from '@/views/devices/OxygenPpmCard.vue'
import PHCard from '@/views/devices/PHCard.vue'
import ConductivityCard from '@/views/devices/ConductivityCard.vue'
import SalinityCard from '@/views/devices/SalinityCard.vue'
import TdsKciCard from '@/views/devices/TdsKciCard.vue'
import LastData from '@/views/devices/LastData.vue'
import IndexAirQualityCard from '@/views/devices/IndexAirQualityCard.vue'
import IndexWaterQualityCard from '@/views/devices/IndexWaterQualityCard.vue'

definePage({
  meta: {
    layout: 'blank',
    public: true
  }
})

const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const uuid = route.params.uuid
const device = ref(null)
const channels = ref([])
const canManage = ref(false)
const prediction = ref(null)

const UVChannel = ref(null)
const TemperatureChannel = ref(null)
const HumidityChannel = ref(null)
const WindSpeedChannel = ref(null)
const WindDirectionChannel = ref(null)
const WindGustChannel = ref(null)
const BatteryChannel = ref(null)

const LevelChannel = ref(null)
const WaterTemperatureChannel = ref(null)
const PHChannel = ref(null)
const RedoxChannel = ref(null)
const OxygenSatChannel = ref(null)
const OxygenMglChannel = ref(null)
const OxygenPpmChannel = ref(null)
const ConductivityChannel = ref(null)
const SalinityChannel = ref(null)
const TdsKciChannel = ref(null)

const AirQualityIndexChannel = ref(null)
const WaterQualityIndexChannel = ref(null)

const track = (uuid) => {
  axios.get('/landing/stations/' + uuid + '/track')
}

const updateData = () => {
  axios.get('/landing/stations/' + uuid).then(response => {
    device.value = response.data

    useTitle(device.value.name + ' - ' + t('landing.stations') + ' - Kropie')

    track(device.value.uuid)
  }).catch(e => {
    console.error(e)
    return router.replace({ name: 'stations'})
  })
}


const getLastData = () => {
  axios.get('/landing/stations/' + uuid + '/last').then(response => {
    channels.value = response.data.data

    UVChannel.value = channels.value.find(obj => obj.tag === 'uv')
    TemperatureChannel.value = channels.value.find(obj => obj.tag === 'temperature')
    HumidityChannel.value = channels.value.find(obj => obj.tag === 'humidity')
    WindSpeedChannel.value = channels.value.find(obj => obj.tag === 'windspeed')
    WindDirectionChannel.value = channels.value.find(obj => obj.tag === 'winddirection')
    WindGustChannel.value = channels.value.find(obj => obj.tag === 'windgust')
    BatteryChannel.value = channels.value.find(obj => obj.tag === 'battery')

    LevelChannel.value = channels.value.find(obj => obj.tag === 'level')
    WaterTemperatureChannel.value = channels.value.find(obj => obj.tag === 'watertemperature')
    PHChannel.value = channels.value.find(obj => obj.tag === 'ph')
    RedoxChannel.value = channels.value.find(obj => obj.tag === 'redox')
    OxygenSatChannel.value = channels.value.find(obj => obj.tag === 'oxygensat')
    OxygenMglChannel.value = channels.value.find(obj => obj.tag === 'oxygenmgl')
    OxygenPpmChannel.value = channels.value.find(obj => obj.tag === 'oxygenppm')
    ConductivityChannel.value = channels.value.find(obj => obj.tag === 'conductivity')
    SalinityChannel.value = channels.value.find(obj => obj.tag === 'salinity')
    TdsKciChannel.value = channels.value.find(obj => obj.tag === 'tdskci')

    AirQualityIndexChannel.value = channels.value.find(obj => obj.tag === 'air_quality')
    WaterQualityIndexChannel.value = channels.value.find(obj => obj.tag === 'water_quality')
  }).catch(e => {
    console.error(e)
  })
}


const getPrediction = () => {
  axios.get('/landing/stations/' + uuid + '/prediction').then(response => {
    prediction.value = response.data
  }).catch(e => {
    console.error(e)
  })
}

updateData()
getLastData()
getPrediction()
</script>


<template>
  <div class="help-center-page">
    <Navbar />
    
    <div>
      <div v-if="device" class="py-12 mt-12">
        <VContainer>
          <h3 class="text-h3 text-center my-6">
            {{ device.name }}
          </h3>
          
          <div class="pa-4">
          <VRow
            class="mb-6"
          >
            <VCol
              cols="12"
              md="3"
            >
              <TemperatureCard
                :device="device"
                :prediction="prediction"
                :battery="BatteryChannel"
                :temperature="TemperatureChannel"
                :can-manage="false"
                :public-page="true"
                @update-data="updateData"
              />
            </VCol>
            <VCol
              cols="12"
              md="9"
            >
              <WeeklyCards :prediction="prediction" />
              <VRow v-if="device">
                <VCol
                  xs6
                  v-if="WindSpeedChannel"
                >
                  <WindCard
                    class="fill-height"
                    :speed="WindSpeedChannel"
                    :direction="WindDirectionChannel"
                    :gust="WindGustChannel"
                  />
                </VCol>
                <VCol
                  xs6
                  v-if="UVChannel"
                >
                  <UVCard
                    class="fill-height"
                    :uv="UVChannel"
                  />
                </VCol>
                <VCol
                  xs6
                  v-if="HumidityChannel"
                >
                  <HumidityCard
                    class="fill-height"
                    :humidity="HumidityChannel"
                  />
                </VCol>
                <VCol
                  v-if="WaterTemperatureChannel"
                  xs6
                >
                  <WaterTemperatureCard
                    class="fill-height"
                    :water-temperature="WaterTemperatureChannel"
                    :device="device"
                    :public="true"
                  />
                </VCol>
                <VCol
                  v-if="PHChannel"
                  xs6
                >
                  <PHCard
                    class="fill-height"
                    :ph="PHChannel"
                    :device="device"
                    :public="true"
                  />
                </VCol>
                <VCol
                  v-if="RedoxChannel"
                  xs6
                >
                  <RedoxCard
                    class="fill-height"
                    :redox="RedoxChannel"
                    :device="device"
                    :public="true"
                  />
                </VCol>
                <VCol
                  v-if="LevelChannel"
                  xs6
                >
                  <LevelCard
                    class="fill-height"
                    :level="LevelChannel"
                  />
                </VCol>
                <VCol
                  v-if="OxygenSatChannel"
                  xs6
                >
                  <OxygenSatCard
                    class="fill-height"
                    :oxygen="OxygenSatChannel"
                    :device="device"
                    :public="true"
                  />
                </VCol>
                <!--VCol
                  v-if="OxygenMglChannel"
                  xs6
                >
                  <OxygenMglCard
                    class="fill-height"
                    :oxygen="OxygenMglChannel"
                  />
                </VCol>
                <VCol
                  v-if="OxygenPpmChannel"
                  xs6
                >
                  <OxygenPpmCard
                    class="fill-height"
                    :oxygen="OxygenPpmChannel"
                  />
                </VCol-->
                <VCol
                  v-if="ConductivityChannel"
                  xs6
                >
                  <ConductivityCard
                    class="fill-height"
                    :conductivity="ConductivityChannel"
                    :device="device"
                    :public="true"
                  />
                </VCol>
                <VCol
                  v-if="SalinityChannel"
                  xs6
                >
                  <SalinityCard
                    class="fill-height"
                    :salinity="SalinityChannel"
                    :device="device"
                    :public="true"
                  />
                </VCol>
                <VCol
                  v-if="TdsKciChannel"
                  xs6
                >
                  <TdsKciCard
                    class="fill-height"
                    :channel="TdsKciChannel"
                    :device="device"
                    :public="true"
                  />
                </VCol>
                <VCol
                  v-if="AirQualityIndexChannel"
                  xs6
                >
                  <IndexAirQualityCard
                    class="fill-height"
                    :channel="AirQualityIndexChannel"
                    :device="device"
                    :public="true"
                  />
                </VCol>
                <VCol
                  v-if="WaterQualityIndexChannel"
                  xs6
                >
                  <IndexWaterQualityCard
                    class="fill-height"
                    :channel="WaterQualityIndexChannel"
                    :device="device"
                    :public="true"
                  />
                </VCol>
              </VRow>

              <LastData
                class="mt-6"
                :device="device"
                :channels="channels"
                :public="true"
              />
            </VCol>
          </VRow>
          
        </div>
          
          
        </VContainer>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.help-center-page {
  .search-header {
    background-size: cover !important;
    padding-block-start: 7rem !important;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .help-center-page {
    .v-container {
      padding-inline: 2rem !important;
    }

    .search-header {
      padding: 5rem !important;
    }
  }
}

@media (max-width: 599px) {
  .help-center-page {
    .search-header {
      padding-block-end: 2rem !important;
      padding-block-start: 4rem !important;
      padding-inline: 2rem !important;
    }
  }
}
</style>
