<script setup>
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const placeholder = ref('')
const loading = ref(false)
const prompt = ref('')

const response = ref({
  prompt: '',
  text: '',
})

axios.get('/assistant').then(r => {
  placeholder.value = r.data.placeholder
}).catch(e => {
  console.error(e)
})

const query = async () => {
  loading.value = true
  response.value.prompt = ''
  response.value.text = ''
  axios.get('/assistant/query', {
    params: {
      prompt: prompt.value,
    },
  }).then(r => {
    response.value.prompt = r.data.prompt
    response.value.text = r.data.text
    prompt.value = ''
  }).catch(e => {
    console.error(e)
  }).finally(() => {
    loading.value = false
  })
}
</script>

<template>
  <div>
    <VCard :title="$t('assistant.title')">
      <VCardText>{{ $t('assistant.description') }}</VCardText>
      <VCardText>
        <VForm @submit.prevent="query">
          <VTextField
            v-model="prompt"
            :placeholder="placeholder.prompt"
            :disabled="loading"
          >
            <template #append>
              <VBtn
                class="mt-n2"
                :loading="loading"
                :disabled="loading"
                type="submit"
                @click="query"
              >
                {{ $t('assistant.send') }}
              </VBtn>
            </template>
          </VTextField>
        </VForm>
      </VCardText>
      <VCardText>
        <h6 class="text-h6">
          {{ response.prompt }}
        </h6>
        <p v-html="response.text"/>
      </VCardText>
    </VCard>
  </div>
</template>
