<script setup>
import axios from '@/helpers/axios'
import { LControlLayers, LGeoJson, LMap, LTileLayer } from "@vue-leaflet/vue-leaflet"
import "leaflet/dist/leaflet.css"
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const parcels = ref([])
const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)

const getData = () => {
  axios.get('/satellite').then(r => {
    parcels.value = r.data.data
  }).catch(e => {
    console.error(e)
  })
}

getData()

const onMapReady = (map, bounds) => {
  map.fitBounds(bounds)
}
</script>

<template>
  <div>
    <VCard
      :title="$t('satellite.title')"
      class="mb-6"
    >
      <VCardText>{{ $t('satellite.description') }}</VCardText>
    </VCard>

    <VRow class="match-height">
      <VCol
        v-for="(item, i) in parcels"
        :key="item.uuid"
        cols="12"
        sm="6"
        lg="4"
      >
        <VCard
          :title="item.name"
        >
          <VCardText>
            <LMap
              :ref="'map-' + i"
              :zoom="10"
              :center="[0,0]"
              :max-bounds="item.bounds"
              :use-global-leaflet="false"
              style="height: 250px; width: 100%;"
              :options="{scrollWheelZoom:false}"
              @ready="(map) => onMapReady(map, item.bounds)"
            >
              <LTileLayer
                :url="urlSattelite"
                layer-type="base"
                name="Mapbox Satellite"
                visible
              />
              <LGeoJson
                v-if="item.geojson"
                :geojson="item.geojson"
                layer-type="overlay"
              />
            </LMap>
          </VCardText>

          <VCardActions>
            <VBtn
              :to="{name: 'satellite-uuid', params: {uuid: item.uuid}}"
            >
              <VIcon
                start
                icon="tabler-eye"
              />
              {{ $t('layout.open') }}
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </div>
</template>
