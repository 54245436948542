<script setup>
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { useI18n } from 'vue-i18n'
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'admin'
  },
})

const { t } = useI18n()

const email = ref('')

const sendEmail = () => {

  axios.get('/debug/email', {
    params: {
      email: email.value,
    },
  }).then(response => {
    toast.success(t('debug.email_sent'), {
      autoClose: 3000,
    })
  }).catch(e => {
    console.error(e)
  })
}
</script>

<template>
  <div>
    <VCard :title="$t('debug.email')">
      <VCardText>
        <VTextField
          v-model="email"
          placeholder="johndoe@mail.com"
        >
          <template #append>
            <VBtn
              class="mt-n2"
              type="submit"
              @click="sendEmail"
            >
              {{ $t('layout.send') }}
            </VBtn>
          </template>
        </VTextField>
      </VCardText>
    </VCard>
  </div>
</template>
