import axios from 'axios'

// Router
const route = useRoute()

const headers = ref({})

const isLoggedIn = !!(
  useCookie("userData").value && useCookie("accessToken").value
);


const axiosIns = axios.create({
// You can add your headers here
// ================================
  baseURL: import.meta.env.VITE_API_BASE_URL,

  // timeout: 1000,
  //headers: headers.value,
})

axiosIns.interceptors.request.use(config => {
  // Retrieve the value from local storage and add it to the request headers
  const accessToken = useCookie("accessToken").value
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }

  return config
})


axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      useCookie("accessToken").value = null
      window.location.href='/login'
    }

    return Promise.reject(error)
  },
)
// Register the plugin within the plugin file
export default axiosIns
