<script setup>
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import AccountSettingsAccount from '@/views/settings/AccountSettingsAccount.vue'

//import AccountSettingsBillingAndPlans from '@/views/settings/AccountSettingsBillingAndPlans.vue'
//import AccountSettingsConnections from '@/views/settings/AccountSettingsConnections.vue'
//import AccountSettingsNotification from '@/views/settings/AccountSettingsNotification.vue'
import AccountSettingsSecurity from '@/views/settings/AccountSettingsSecurity.vue'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()
const route = useRoute()
const activeTab = ref(route.params.tab)

// tabs
const tabs = [
  {
    title: t('settings.account'),
    icon: 'tabler-user',
    tab: 'account',
  },
  {
    title: t('settings.security'),
    icon: 'tabler-lock',
    tab: 'security',
  },
]
</script>

<template>
  <div>
    <VTabs
      v-model="activeTab"
      class="v-tabs-pill"
    >
      <VTab
        v-for="item in tabs"
        :key="item.icon"
        :value="item.tab"
        :to="{ name: 'settings-tab', params: { tab: item.tab } }"
      >
        <VIcon
          size="20"
          start
          :icon="item.icon"
        />
        {{ item.title }}
      </VTab>
    </VTabs>

    <VWindow
      v-model="activeTab"
      class="mt-6 disable-tab-transition"
      :touch="false"
    >
      <!-- Account -->
      <VWindowItem value="account">
        <AccountSettingsAccount />
      </VWindowItem>

      <!-- Security -->
      <VWindowItem value="security">
        <AccountSettingsSecurity />
      </VWindowItem>
    </VWindow>
  </div>
</template>
