<script setup>
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import UserBioPanel from '@/views/users/UserBioPanel.vue'
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'admin'
  },
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const uuid = route.params.uuid
const user = ref(null)

const updateData = () => {
  axios.get('/admin/users/' + uuid).then(response => {
    user.value = response.data
  }).catch(e => {
    return router.push('/admin/users')
    console.error(e)
  })
}

updateData()
</script>

<template>
  <div>
    <VRow v-if="user">
      <VCol
        cols="12"
        md="5"
        lg="4"
      >
        <UserBioPanel
          :user="user"
          @update="updateData"
        />
      </VCol>

      <VCol
        cols="12"
        md="7"
        lg="8"
      >
      </VCol>
    </VRow>
  </div>
</template>
