<script setup>
import { useI18n } from 'vue-i18n'
import moment from "moment";
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()

const searchQuery = ref('')
const rowPerPage = ref(10)
const currentPage = ref(1)
const totalPage = ref(1)
const totalItems = ref(0)
const items = ref([])

watchEffect(() => {
  axios.get('/analyses', {
    params: {
      q: searchQuery.value,
      perPage: rowPerPage.value,
      currentPage: currentPage.value,
    },
  }).then(response => {
    items.value = response.data.data
    totalPage.value = response.data.meta.last_page
    totalItems.value = response.data.meta.total
  }).catch(e => {
    console.error(e)
  })
})

watchEffect(() => {
  if (currentPage.value > totalPage.value)
    currentPage.value = totalPage.value
})


// 👉 Computing pagination data
const paginationData = computed(() => {
  const firstIndex = items.value.length ? (currentPage.value - 1) * rowPerPage.value + 1 : 0
  const lastIndex = items.value.length + (currentPage.value - 1) * rowPerPage.value
  
  return t('tables.showing', { firstIndex, lastIndex, totalItems: totalItems.value })
})

const resolveTypeVariant = data => {
  if (data === 'water')
    return { color: 'success', text: t('analyses.water') }
    
  if (data === 'soil')
    return { color: 'success', text: t('analyses.soil') }
  
  return { color: 'success', text: t('analyses.leaf') }

}

const resolveBoolVariant = (data) => {
  if (data)
    return { color: 'success', text: t('layout.yes') }

  return { color: 'error', text: t('layout.no') }
}

const resolveStatusVariant = (item) => {
  if (item.pending)
    return { color: 'warning', text: t('analyses.pending') }

  if (item.refused_at)
    return { color: 'error', text: t('analyses.refused') }
  
  if (item.accepted_at && item.finished_at)
    return { color: 'primary', text: t('analyses.finished') }

  return { color: 'success', text: t('analyses.accepted') }
}
</script>

<template>
  <div>
    <VCard class="text-center mb-6">
      <VCardText class="d-flex flex-column justify-center align-center">
        <VAvatar
          color="primary"
          variant="tonal"
          size="50"
          class="mb-4"
        >
          <VIcon
            size="2rem"
            icon="tabler-plus"
          />
        </VAvatar>

        <h5 class="text-h5">
          {{ t('analyses.request_new_analysis') }}
        </h5>
      </VCardText>

      <VCardText>
        <p>
          {{ t('analyses.request_new_analysis_description') }}
        </p>
      </VCardText>

      <VCardText class="justify-center">
        <VBtn
          variant="elevated"
          :to="{ name: 'analyses-new' }"
        >
          {{ t('analyses.start') }}
        </VBtn>
      </VCardText>
    </VCard>
    <VCard
      v-if="items"
      id="items-list"
      :title="$t('analyses.title') + ' 📊'"
    >
      <VCardText class="d-flex align-center flex-wrap gap-4">
        <!-- 👉 Rows per page -->
        <div
          class="d-flex align-center"
          style="width: 150px;"
        >
          <span class="text-no-wrap me-3">{{ $t('tables.show') }}</span>
          <VSelect
            v-model="rowPerPage"
            density="compact"
            :items="[10, 20, 30, 50]"
          />
        </div>

        <VSpacer />

        <div class="d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div class="items-list-filter">
            <VTextField
              v-model="searchQuery"
              :placeholder="$t('analyses.search_request')"
              density="compact"
            />
          </div>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION Table -->
      <VTable class="text-no-wrap items-list-table">
        <!-- 👉 Table head -->
        <thead class="text-uppercase">
          <tr>
            <th scope="col">
              {{ $t('analyses.number') }}
            </th>

            <th scope="col">
              {{ $t('analyses.type') }}
            </th>

            <th scope="col">
              {{ $t('analyses.status') }}
            </th>

            <th scope="col">
              {{ $t('analyses.paid') }}
            </th>

            <th scope="col">
              {{ $t('analyses.date') }}
            </th>

            <th scope="col" />

          </tr>
        </thead>

        <!-- 👉 Table Body -->
        <tbody>
          <tr
            v-for="item in items"
            :key="item.uuid"
            style="height: 3.75rem;"
          >
            <!-- 👉 Number -->
            <td>
              <RouterLink :to="{ name: 'analyses-uuid', params: { uuid: item.uuid } }">
                #{{ item.number }}
              </RouterLink>
            </td>

            <!-- 👉 Type -->
            <td>
              <VChip
                :color="resolveTypeVariant(item.type).color"
                class="font-weight-medium"
                size="small"
              >
                {{ resolveTypeVariant(item.type).text }}
              </VChip>
            </td>

            <!-- 👉 Status -->
            <td>
              <VChip
                :color="resolveStatusVariant(item).color"
                class="font-weight-medium"
                size="small"
              >
                {{ resolveStatusVariant(item).text }}
              </VChip>
            </td>

            <td>
              <VChip
                :color="resolveBoolVariant(item.paid_at).color"
                class="font-weight-medium"
                size="small"
              >
                {{ resolveBoolVariant(item.paid_at).text }}
              </VChip>
            </td>

            <!-- 👉 Date -->
            <td>
              {{ moment.utc(item.created_at).local().format("Y-MM-DD HH:mm") }}
            </td>

            <!-- 👉 Actions -->
          <td style="width: 8rem;">
            <VBtn
              icon
              variant="text"
              color="default"
              size="x-small"
              :to="{ name: 'analyses-uuid', params: { uuid: item.uuid } }"
            >
              <VIcon
                :size="22"
                icon="tabler-eye"
              />
            </VBtn>

            </td>
          </tr>
        </tbody>

        <!-- 👉 table footer  -->
        <tfoot v-show="!items.length">
          <tr>
            <td
              colspan="8"
              class="text-center text-body-1"
            >
              {{ $t('analyses.no_requests') }}
            </td>
          </tr>
        </tfoot>
      </VTable>
      <!-- !SECTION -->

      <VDivider />

      <!-- SECTION Pagination -->
      <VCardText class="d-flex align-center flex-wrap gap-4 py-3">
        <!-- 👉 Pagination meta -->
        <span class="text-sm text-disabled">
          {{ paginationData }}
        </span>

        <VSpacer />

        <!-- 👉 Pagination -->
        <VPagination
          v-model="currentPage"
          size="small"
          :total-visible="5"
          :length="totalPage"
        />
      </VCardText>
      <!-- !SECTION -->
    </VCard>
  </div>
</template>

<style lang="scss">
#items-list {
  .items-list-actions {
    inline-size: 8rem;
  }

  .items-list-filter {
    inline-size: 12rem;
  }
}
</style>
