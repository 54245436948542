<script setup>
import Footer from '@/views/front-pages/front-page-footer.vue'
import Navbar from '@/views/front-pages/front-page-navbar.vue'
import laptopGirl from '@images/illustrations/laptop-girl.png'
import { useTitle } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'blank',
    public: true
  }
})

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const id = route.params.id

const webcam = ref(null)

const track = (camera_id) => {
  axios.get('/landing/webcams/' + camera_id + '/track')
}

const updateData = () => {
  axios.get('/landing/webcams/' + id).then(response => {
    webcam.value = response.data
    useTitle(webcam.value.location.city + ' - ' + t('landing.webcams') + ' - Kropie')

    track(webcam.value.camera_id)
  }).catch(e => {
    console.error(e)
  })
}

updateData()

</script>

<template>

<div class="bg-surface">
    <!-- 👉 Navbar  -->
    <Navbar />

    <!-- 👉 Content -->
    <VContainer>

      <VRow class="mt-4">
        <VCol
          cols="12"
        >

        <VCardText v-if="webcam" class="text-center mt-14 pb-16">
          <h2 class="text-h2 mb-2">
            {{ webcam.location.city }} - {{ webcam.location.country }}
          </h2>
          <p class="text-disabled mb-6">
            {{ webcam.title }}
          </p>

          <VCard>
            <iframe class="w-100" style="height: 80vh;" :src="webcam.player.day"></iframe>
          </VCard>


        </VCardText>

        </VCol>

      </VRow>
    </VContainer>

    <!-- 👉 Footer  -->
    <Footer class="mt-15" />
  </div>
</template>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 1rem;
}
</style>

<style lang="scss">
.v-breadcrumbs{
  .v-breadcrumbs-item{
    padding: 0 !important;
  }
}

</style>