<script setup>
import { useI18n } from 'vue-i18n'
import Users from '@/views/analyses/Users.vue'
import Requests from '@/views/analyses/AdminRequests.vue'
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'admin'
  },
})

const { t } = useI18n()
const route = useRoute()
const uuid = route.params.uuid
const provider = ref({})

const getProvider = () => {
  axios.get('/admin/analyses/' + uuid).then(response => {
    provider.value = response.data
  }).catch(e => {
    console.error(e)
  })
}

getProvider()
</script>

<template>
  <div>
    <VCard
      :title="provider.name"
      class="mb-6"
    >
    </VCard>
    <Users
      :provider="provider"
    />
    <Requests
      :provider="provider"
    />
  </div>
</template>
