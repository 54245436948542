<script setup>
import { useI18n } from 'vue-i18n'
import moment from "moment";
import axios from '@/helpers/axios'
import AnalysesProviders from '@/views/analyses/AnalysesProviders.vue'
import AdminRequestsNotAttributed from '@/views/analyses/AdminRequestsNotAttributed.vue'

definePage({
  meta: {
    layout: 'default',
    role: 'admin'
  },
})

const { t } = useI18n()


</script>


<template>
  <div>
    <div class="d-flex gap-2 align-center mb-2 flex-wrap">
      <h4 class="text-h4">
        {{ t('analyses.title') }}
      </h4>
    </div>
    
    <AnalysesProviders class="mb-6" />
    <AdminRequestsNotAttributed />
  </div>
</template>
