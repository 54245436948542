<script setup>
import { useTheme } from 'vuetify'
import ScrollToTop from '@core/components/ScrollToTop.vue'
import initCore from '@core/initCore'
import {
  initConfigStore,
  useConfigStore,
} from '@core/stores/config'
import { hexToRgb } from '@layouts/utils'
import axios from '@/helpers/axios'
import { watch } from 'vue'

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
initCore()
initConfigStore()

const configStore = useConfigStore()

const { locale } = useI18n({ useScope: 'global' })

const getUserData = () => {
  const isLoggedIn = !!(
    useCookie("userData").value && useCookie("accessToken").value
  );

  if (!isLoggedIn) {
    return
  }

  axios.get('/auth/user').then(r => {
    useCookie("userData").value = r.data
    useCookie("userRoles").value = r.data.roles
  }).catch(e => {
    console.error(e)
  })
}

(async () => {
  getUserData()
})()

setInterval(() => {
  getUserData()
}, 30000)

const changeUserLocale = (locale) => {
  const isLoggedIn = !!(
    useCookie("userData").value && useCookie("accessToken").value
  );

  if (!isLoggedIn) {
    return
  }

  axios.post('/locale', {
    locale
  }).then(r => {
  }).catch(e => {
    console.error(e)
  })
}

watch(locale, val => {
  changeUserLocale(val)
})
locale
</script>

<template>
  <VLocaleProvider :rtl="configStore.isAppRTL">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <ScrollToTop />
    </VApp>
  </VLocaleProvider>
</template>
