
<script setup>
import CreateNotebookDrawer from '@/views/notebooks/CreateNotebookDrawer.vue'
import moment from "moment";
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const notebooks = ref([])

// const selfUser = ref(JSON.parse(localStorage.getItem('user')))
const showCreateNotebookDrawer = ref(false)

const getNotebooks = () => {
  axios.get('/notebooks').then(r => {
    notebooks.value = r.data.data
  }).catch(e => {
    console.error(e)
  })
}

getNotebooks()
</script>

<template>
  <div>
    <VCard
      :title="$t('notebooks.title')"
      class="mb-6"
    >
      <VCardText>{{ $t('notebooks.description') }}</VCardText>
      <VCardActions>
        <VBtn
          @click="showCreateNotebookDrawer = true"
        >
          <VIcon
            start
            icon="tabler-plus"
          />
          {{ $t('notebooks.create') }}
        </VBtn>
      </VCardActions>
    </VCard>

    <VRow class="match-height">
      <VCol
        v-for="notebook in notebooks"
        :key="notebook.uuid"
        cols="12"
        md="6"
        lg="4"
      >
        <VCard
          :title="notebook.name"
          :subtitle="notebook.last_entry ? moment.utc(notebook.last_entry).local().format('Y-MM-DD HH:mm') : $t('notebooks.no_notes')"
        >
          <VCardText>
            <h4>
              {{ $t('notebooks.notes_number', { number: notebook.notes }) }}
            </h4>
          </VCardText>

          <VCardActions>
            <VBtn
              :to="{ name: 'notebooks-uuid', params: { uuid: notebook.uuid } }"
            >
              <VIcon
                start
                icon="tabler-eye"
              />
              {{ $t('layout.open') }}
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <CreateNotebookDrawer
      :is-drawer-open="showCreateNotebookDrawer"
      @update:is-drawer-open="(val) => showCreateNotebookDrawer = val"
      @submit="getNotebooks"
    />
  </div>
</template>
