<script setup>
import CreateGroupDrawer from '@/views/groups/CreateGroupDrawer.vue'
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const groups = ref([])
const selfUser = ref(useCookie('userData').value)
const showCreateGroupDrawer = ref(false)

const getGroups = () => {
  axios.get('/groups').then(r => {
    groups.value = r.data.data
  }).catch(e => {
    console.error(e)
  })
}

getGroups()
</script>

<template>
  <div>
    <VCard
      :title="$t('groups.title')"
      class="mb-6"
    >
      <VCardText>{{ $t('groups.description') }}</VCardText>
      <VCardActions>
        <VBtn
          @click="showCreateGroupDrawer = true"
        >
          <VIcon
            start
            icon="tabler-plus"
          />
          {{ $t('groups.create_group') }}
        </VBtn>
      </VCardActions>
    </VCard>

    <VRow class="match-height">
      <VCol
        v-for="group in groups"
        :key="group.uuid"
        cols="4"
      >
        <VCard
          :title="group.name"
          :subtitle="group.admin_uuid = selfUser.uuid ? $t('groups.admin') : $t('groups.member') "
        >
          <VCardText>
            <p>
              {{ $t('groups.devices_number', { number: group.devices_count }) }}
            </p>
            <div class="d-flex justify-space-between align-center">
              <span class="font-weight-medium">{{ group.users.length === 1 ? $t('groups.member_number', { number: group.users.length }) : $t('groups.members_number', { number: group.users.length }) }}</span>

              <div class="v-avatar-group">
                <VAvatar
                  v-for="user in group.users.slice(0, 5)"
                  :key="user.uuid"
                  size="45"
                >
                  <VImg :src="user.avatar_thumbnail" />
                  <VTooltip
                    activator="parent"
                    location="start"
                  >
                    {{ user.name }}
                  </VTooltip>
                </VAvatar>
              </div>
            </div>
          </VCardText>

          <VCardActions>
            <VBtn
              :to="{name: 'groups-uuid', params: {uuid: group.uuid}}"
            >
              <VIcon
                start
                icon="tabler-eye"
              />
              {{ $t('layout.open') }}
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
    <CreateGroupDrawer
      :is-drawer-open="showCreateGroupDrawer"
      @update:is-drawer-open="(val) => showCreateGroupDrawer = val"
      @submit="getGroups"
    />
  </div>
</template>
