<script setup>
import { useI18n } from 'vue-i18n'
import Map from '@/views/groups/Map.vue'
import Devices from '@/views/groups/Devices.vue'
import Users from '@/views/groups/Users.vue'
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()
const route = useRoute()
const uuid = route.params.uuid
const group = ref({})
const isAdmin = ref(false)


const getGroup = () => {
  axios.get('/groups/' + uuid).then(response => {
    group.value = response.data
  }).catch(e => {
    console.error(e)
  })
}

getGroup()
</script>

<template>
  <div>
    <Map :group="group" />
    <Devices
      v-if="group !== null"
      :group="group"
      :is-admin="group.is_admin"
    />
    <Users
      v-if="group !== null"
      :group="group"
      :is-admin="group.is_admin"
    />
  </div>
</template>
