<script setup>
import DeleteDialog from '@/views/general/DeleteDialog.vue'
import CreateNoteDrawer from '@/views/notebooks/CreateNoteDrawer.vue'
import EditNoteDrawer from '@/views/notebooks/EditNoteDrawer.vue'
import LocationDialog from '@/views/notebooks/LocationDialog.vue'
import AppDateTimePicker from '@core/components/app-form-elements/AppDateTimePicker.vue'
import { useI18n } from 'vue-i18n'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import moment from "moment";
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const uuid = route.params.uuid
const notebook = ref({})
const types = ref([])
const showCreateNoteDrawer = ref(false)
const isLocationDialogVisible = ref(false)
const showDeleteNotebookDialog = ref(false)
const showDeleteNoteDialog = ref(false)
const showEditNoteDrawer = ref(false)

const entryLocation = ref({})

const selectedType = ref()
const dateRange = ref('')

const searchQuery = ref('')
const rowPerPage = ref(10)
const currentPage = ref(1)
const totalPage = ref(1)
const totalItems = ref(0)
const items = ref([])

const deleteNoteItem = ref(null)
const editNoteItem = ref(null)


const openDeleteNote = note => {
  deleteNoteItem.value = note
  showDeleteNoteDialog.value = true
}

const openEditNote = note => {
  editNoteItem.value = note
  showEditNoteDrawer.value = true
}

const getNotebook = () => {
  axios.get('/notebooks/' + uuid).then(response => {
    notebook.value = response.data
  }).catch(e => {
    console.error(e)
  })
}

const getNoteType = () => {
  axios.get('/notebooks/note-type').then(response => {
    types.value = response.data.data.map(function(type) {
      type.name = t('notebooks.types.' + type.key)

      return type
    })
  }).catch(e => {
    console.error(e)
  })
}


const getNotes = () => {
  axios.get('/notebooks/' + uuid + '/notes', {
    params: {
      q: searchQuery.value,
      perPage: rowPerPage.value,
      currentPage: currentPage.value,
      type: selectedType.value,
      dates: dateRange.value,
    },
  }).then(response => {
    items.value = response.data.data
    totalPage.value = response.data.meta.last_page
    totalItems.value = response.data.meta.total
  }).catch(e => {
    console.error(e)
  })
}

watchEffect(() => {
  getNotes()
})


watchEffect(() => {
  if (currentPage.value > totalPage.value)
    currentPage.value = totalPage.value
})


// 👉 Computing pagination data
const paginationData = computed(() => {
  const firstIndex = items.value.length ? (currentPage.value - 1) * rowPerPage.value + 1 : 0
  const lastIndex = items.value.length + (currentPage.value - 1) * rowPerPage.value
  
  return t('tables.showing', { firstIndex, lastIndex, totalItems: totalItems.value })
})

const deleteNotebook = () => {
  axios.delete('/notebooks/' + uuid).then(response => {
    showDeleteNotebookDialog.value = false
    toast.success(t('notebooks.deleted_notebook'), {
      autoClose: 3000,
    })

    return router.replace({ name: 'notebooks' })
  }).catch(e => {
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
  })
}

const deleteNote = () => {
  axios.delete('/notebooks/' + uuid + '/' + deleteNoteItem.value.uuid).then(response => {
    showDeleteNoteDialog.value = false
    toast.success(t('notebooks.deleted_note'), {
      autoClose: 3000,
    })

    return getNotes()
  }).catch(e => {
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
  })
}

const exportNotebook = () => {
  axios.get('/notebooks/' + uuid + '/export').then(response => {
    toast.success(t('notebooks.exporting_notebook'), {
      autoClose: 3000,
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', notebook.value.name + '_' + notebook.value.uuid + '.csv')
    document.body.appendChild(link)
    link.click()
  }).catch(e => {
    console.log(e)
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
  })
}

getNotebook()
getNoteType()
</script>

<template>
  <div>
    <VCard
      :title="notebook.name"
      :subtitle="notebook.last_entry === null ? '' : $t('notebooks.last_note', { date: moment.utc(notebook.last_entry).local().format('Y-MM-DD HH:mm') })"
      class="mb-6"
    >
      <template #append>
        <div class="me-n2">
          <VBtn
            icon
            color="default"
            size="x-small"
            variant="plain"
          >
            <VIcon
              size="22"
              icon="tabler-dots-vertical"
            />

            <VMenu activator="parent">
              <VList>
                <VListItem
                  @click="showDeleteNotebookDialog = true"
                >
                  <VListItemTitle>{{ $t('notebooks.delete_notebook') }}</VListItemTitle>
                </VListItem>
                <VListItem
                  @click="exportNotebook"
                >
                  <VListItemTitle>{{ $t('layout.export') }}</VListItemTitle>
                </VListItem>
              </VList>
            </VMenu>
          </VBtn>
        </div>
      </template>
      <VCardActions>
        <VBtn
          @click="showCreateNoteDrawer = true"
        >
          <VIcon
            start
            icon="tabler-plus"
          />
          {{ $t('notebooks.add_note') }}
        </VBtn>
      </VCardActions>
    </VCard>
    
    <VCard
      v-if="items"
      id="items-list"
      :title="$t('notebooks.notes')"
    >
      <VCardText>
        <VRow>
          <!-- 👉 Select Type -->
          <VCol
            cols="12"
            sm="4"
          >
            <VSelect
              v-model="selectedType"
              :label="$t('notebooks.select_type')"
              :items="types"
              item-title="name"
              item-value="key"
              clearable
              clear-icon="tabler-x"
            />
          </VCol>
          <!-- 👉 Select Dates -->
          <VCol
            cols="12"
            sm="4"
          >
            <AppDateTimePicker
              v-model="dateRange"
              :label="$t('notebooks.date')"
              :config="{ mode: 'range' }"
            />
          </VCol>
        </VRow>
      </VCardText>
      <VCardText class="d-flex align-center flex-wrap gap-4">
        <!-- 👉 Rows per page -->
        <div
          class="d-flex align-center"
          style="width: 150px;"
        >
          <span class="text-no-wrap me-3">{{ $t('tables.show') }}</span>
          <VSelect
            v-model="rowPerPage"
            density="compact"
            :items="[10, 20, 30, 50]"
          />
        </div>

        <VSpacer />

        <div class="d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div class="items-list-filter">
            <VTextField
              v-model="searchQuery"
              :placeholder="$t('notebooks.search_note')"
              density="compact"
            />
          </div>
        </div>
      </VCardText>

      <VDivider />
      <VCardText>
        <VTimeline
          v-if="items.length > 0"
          side="end"
          align="start"
          truncate-line="both"
          density="compact"
          class="v-timeline-density-compact"
        >
          <VTimelineItem
            v-for="entry in items"
            :key="entry.uuid"
            dot-color="primary"
            size="x-small"
          >
            <!-- 👉 Header -->
            <div class="d-flex justify-space-between">
              <h6 class="text-base font-weight-semibold me-3">
                {{ $t('notebooks.types.' + entry.type) }}
              </h6>
              <div class="d-flex align-center">
                <span class="text-sm text-disabled">{{ moment.utc(entry.created_at).local().format('Y-MM-DD HH:mm') }}</span>
                <div class="">
                  <VBtn
                    icon
                    color="default"
                    size="x-small"
                    variant="plain"
                  >
                    <VIcon
                      size="22"
                      icon="tabler-dots-vertical"
                    />

                    <VMenu activator="parent">
                      <VList>
                        <VListItem
                          @click="openEditNote(entry)"
                        >
                          <VListItemTitle>{{ $t('layout.edit') }}</VListItemTitle>
                        </VListItem>
                        <VListItem
                          @click="openDeleteNote(entry)"
                        >
                          <VListItemTitle>{{ $t('layout.delete') }}</VListItemTitle>
                        </VListItem>
                      </VList>
                    </VMenu>
                  </VBtn>
                </div>
              </div>
            </div>

            <!-- 👉 Content -->
            <p class="mb-2 mt-2">
              {{ entry.description }}
            </p>
            <div
              v-if="entry.photos.length > 0"
              class="ma-auto pa-5"
            >
              <VImg
                v-for="photo in entry.photos"
                :key="photo.uuid"
                :width="176"
                :src="photo.thumbnail_url"
                class="rounded"
              />
            </div>
            <div
              v-if="entry.location !== null"
              class="d-flex align-center"
            >
              <div
                class="d-flex align-center"
                @click="entryLocation = entry.location; isLocationDialogVisible = true"
              >
                <VIcon
                  start
                  size="18"
                  color="warning"
                  icon="tabler-map"
                />
                <h6 class="text-base">
                  {{ $t('notebooks.location') }}
                </h6>
              </div>
            </div>

            <p class="mb-1 mt-1">
              <VAvatar
                :image="entry.user.avatar_thumbnail"
                size="20"
              />
              <span class="ml-2">{{ entry.user.name }}</span>
            </p>
          </VTimelineItem>
        </VTimeline>
        <VAlert
          v-else
          border="start"
          color="warning"
          variant="tonal"
        >
          {{ $t('notebooks.no_notes') }}
        </VAlert>
      </VCardText>

      <VDivider />

      <!-- SECTION Pagination -->
      <VCardText class="d-flex align-center flex-wrap gap-4 py-3">
        <!-- 👉 Pagination meta -->
        <span class="text-sm text-disabled">
          {{ paginationData }}
        </span>

        <VSpacer />

        <!-- 👉 Pagination -->
        <VPagination
          v-model="currentPage"
          size="small"
          :total-visible="5"
          :length="totalPage"
        />
      </VCardText>
      <!-- !SECTION -->
    </VCard>

    <CreateNoteDrawer
      :is-drawer-open="showCreateNoteDrawer"
      :types="types"
      @update:is-drawer-open="(val) => showCreateNoteDrawer = val"
      @submit="getNotes"
    />

    <LocationDialog
      :is-dialog-open="isLocationDialogVisible"
      :location="entryLocation"
      @update:is-dialog-open="(val) => isLocationDialogVisible = val"
    />    

    <DeleteDialog
      :is-dialog-open="showDeleteNotebookDialog"
      :title="$t('notebooks.delete_notebook')"
      :text="$t('notebooks.delete_notebook_confirm')"
      @update:is-dialog-open="(val) => showDeleteNotebookDialog = val"
      @submit="deleteNotebook"
    />

    <DeleteDialog
      :is-dialog-open="showDeleteNoteDialog"
      :title="$t('notebooks.delete_note')"
      :text="$t('notebooks.delete_note_confirm')"
      @update:is-dialog-open="(val) => showDeleteNoteDialog = val"
      @submit="deleteNote"
    />
    <EditNoteDrawer
      v-if="editNoteItem"
      :types="types"
      :is-drawer-open="showEditNoteDrawer"
      :note="editNoteItem"
      :notebook-uuid="notebook.uuid"
      @update:is-drawer-open="(val) => showEditNoteDrawer = val"
      @submit="getNotes"
    />
  </div>
</template>
