<script setup>
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg'
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { useI18n } from 'vue-i18n'
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'blank',
    role: 'user'
  },
})

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const user = ref({})
const loading = ref(false)

const getUserData = () => {
  axios.get('/auth/user').then(r => {
    user.value = r.data
    localStorage.setItem('user', JSON.stringify(user.value))
    if (user.value.verified) {
      router.replace({ name: 'dashboard' })
    }
  }).catch(e => {
    console.error(e)
  })
}

getUserData()

setInterval(() => {
  getUserData()
}, 15000)

const resend = () => {
  loading.value = true
  axios.post('/auth/resend').then(r => {
    toast.success(t('auth.email_resent'), {
      autoClose: 3000,
    })
  }).catch(e => {
    console.error(e)
  }).finally(() => {
    loading.value = false
  })
}

const logout = () => {
  axios.get('/auth/logout').then(r => {
    useCookie('accessToken').value = null
    // Redirect to `to` query if exist or redirect to index route
    router.replace({ name: 'login' })
  })
}
</script>

<template>
  <div class="auth-wrapper d-flex align-center justify-center pa-4">
    <div class="position-relative my-sm-16">
      <!-- 👉 Top shape -->
      <VImg
        :src="authV1TopShape"
        class="auth-v1-top-shape d-none d-sm-block"
      />

      <!-- 👉 Bottom shape -->
      <VImg
        :src="authV1BottomShape"
        class="auth-v1-bottom-shape d-none d-sm-block"
      />

      <!-- 👉 Auth card -->
      <VCard
        class="auth-card pa-4"
        max-width="448"
      >
        <VCardItem class="justify-center">
          <template #prepend>
            <div class="d-flex">
              <VNodeRenderer :nodes="themeConfig.app.logo" />
            </div>
          </template>

          <VCardTitle class="font-weight-bold text-h5 py-1">
            {{ themeConfig.app.title }}
          </VCardTitle>
        </VCardItem>

        <VCardText class="pt-2">
          <h5 class="text-h5 font-weight-semibold mb-1">
            {{ $t('auth.verify_email') }} ✉️
          </h5>
          <p>
            {{ $t('auth.verify_email_description', { email: user.email }) }}
          </p>

          <VBtn
            block
            :loading="loading"
            class="mb-6"
            @click="resend"
          >
            {{ $t('auth.resend') }}
          </VBtn>

          <div class="d-flex align-center justify-center" @click="logout">
            <a href="#">{{ $t('auth.logout') }}</a>
          </div>
        </VCardText>
      </VCard>
    </div>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
