<script setup>
import { useI18n } from 'vue-i18n'
import moment from "moment";
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'admin'
  },
})

const { t } = useI18n()

const searchQuery = ref('')
const rowPerPage = ref(10)
const currentPage = ref(1)
const totalPage = ref(1)
const totalItems = ref(0)
const items = ref([])

watchEffect(() => {
  axios.get('/admin/contacts', {
    params: {
      q: searchQuery.value,
      perPage: rowPerPage.value,
      currentPage: currentPage.value,
    },
  }).then(response => {
    items.value = response.data.data
    totalPage.value = response.data.meta.last_page
    totalItems.value = response.data.meta.total
  }).catch(e => {
    console.error(e)
  })
})


watchEffect(() => {
  if (currentPage.value > totalPage.value)
    currentPage.value = totalPage.value
})


// 👉 Computing pagination data
const paginationData = computed(() => {
  const firstIndex = items.value.length ? (currentPage.value - 1) * rowPerPage.value + 1 : 0
  const lastIndex = items.value.length + (currentPage.value - 1) * rowPerPage.value
  
  return t('tables.showing', { firstIndex, lastIndex, totalItems: totalItems.value })
})

const resolveAnsweredVariant = (answered) => {
  if (answered === true)
    return { color: 'success', text: t('layout.yes') }

  return { color: 'error', text: t('layout.no') }
}

</script>

<template>
  <VCard
    v-if="items"
    id="items-list"
    :title="$t('navigation.contacts')"
  >
    <VCardText class="d-flex align-center flex-wrap gap-4">
      <!-- 👉 Rows per page -->
      <div
        class="d-flex align-center"
        style="width: 150px;"
      >
        <span class="text-no-wrap me-3">{{ $t('tables.show') }}</span>
        <VSelect
          v-model="rowPerPage"
          density="compact"
          :items="[10, 20, 30, 50]"
        />
      </div>

      <VSpacer />

      <div class="d-flex align-center flex-wrap gap-4">
        <!-- 👉 Search  -->
        <div class="items-list-filter">
          <VTextField
            v-model="searchQuery"
            :placeholder="$t('contacts.search_contact')"
            density="compact"
          />
        </div>
      </div>
    </VCardText>

    <VDivider />

    <!-- SECTION Table -->
    <VTable class="text-no-wrap items-list-table">
      <!-- 👉 Table head -->
      <thead class="text-uppercase">
        <tr>
          <th scope="col">
            {{ $t('contacts.name') }}
          </th>

          <th scope="col">
            {{ $t('contacts.email') }}
          </th>

          <th scope="col">
            {{ $t('contacts.message') }}
          </th>

          <th scope="col">
            {{ $t('contacts.answered') }}
          </th>

          <th scope="col">
            {{ $t('contacts.date') }}
          </th>
        </tr>
      </thead>

      <!-- 👉 Table Body -->
      <tbody>
        <tr
          v-for="item in items"
          :key="item.uuid"
          style="height: 3.75rem;"
        >
          <!-- 👉 Name -->
          <td>
            {{ item.name }}
          </td>

          <!-- 👉 Email -->
          <td>
            {{ item.email }}
          </td>

          <!-- 👉 Message -->
          <td>
            {{ item.message }}
          </td>

          <!-- 👉 Message -->
          <td>
            <VChip
              :color="resolveAnsweredVariant(item.answered).color"
              class="font-weight-medium"
              size="small"
            >
              {{ resolveAnsweredVariant(item.answered).text }}
            </VChip>
          </td>

          <!-- 👉 Date -->
          <td>
            {{ moment.utc(item.created_at).local().format("Y-MM-DD HH:mm") }}
          </td>
        </tr>
      </tbody>

      <!-- 👉 table footer  -->
      <tfoot v-show="!items.length">
        <tr>
          <td
            colspan="8"
            class="text-center text-body-1"
          >
            {{ $t('contacts.no_contacts') }}
          </td>
        </tr>
      </tfoot>
    </VTable>
    <!-- !SECTION -->

    <VDivider />

    <!-- SECTION Pagination -->
    <VCardText class="d-flex align-center flex-wrap gap-4 py-3">
      <!-- 👉 Pagination meta -->
      <span class="text-sm text-disabled">
        {{ paginationData }}
      </span>

      <VSpacer />

      <!-- 👉 Pagination -->
      <VPagination
        v-model="currentPage"
        size="small"
        :total-visible="5"
        :length="totalPage"
      />
    </VCardText>
    <!-- !SECTION -->
  </VCard>
</template>

<style lang="scss">
#items-list {
  .items-list-actions {
    inline-size: 8rem;
  }

  .items-list-filter {
    inline-size: 12rem;
  }
}
</style>
