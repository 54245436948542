<script setup>
import axios from '@/helpers/axios';
import Footer from '@/views/front-pages/front-page-footer.vue';
import Navbar from '@/views/front-pages/front-page-navbar.vue';
import Breadcrumbs from '@/views/general/Breadcrumbs.vue';
import AppSearchHeaderBg from '@images/pages/app-search-header-bg.png';
import { useTitle } from '@vueuse/core';
import moment from "moment";
import { useI18n } from 'vue-i18n';
import { register } from 'swiper/element/bundle'
import FsLightbox from "fslightbox-vue/v3";
import { LControlLayers, LGeoJson, LMap, LTileLayer, LImageOverlay, LMarker } from "@vue-leaflet/vue-leaflet"
import "leaflet/dist/leaflet.css"
import LFullScreen from "vue-leaflet-fullscreen"
import "vue-leaflet-fullscreen/dist/style.css"

definePage({ meta: { layout: 'blank', public: true } })

const observation = ref(null)

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const uuid = route.params.uuid

const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlOutdoors = ref('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const zoom = ref(17)
const mapRef = ref()

const bread = ref([])

const toggler = ref(false)
const selected = ref(null)
const selectedType = ref('image')

const openLightbox = (val, type) => {
  selected.value = [val]
  toggler.value = !toggler.value
  selectedType.value = type
}

register()

function onMapReady(m) {
  mapRef.value = m
}

const getData = () => {
  axios.get('/landing/observation/' + uuid).then(response => {
    observation.value = response.data
    useTitle(observation.value.title + ' - Kropie')
    track(observation.value.uuid)
  }).catch(e => {
    console.error(e)
    router.replace({ name: 'blog' })
  })
}

const track = (slug) => {
  axios.get('/landing/observation/' + uuid + '/track')
}

getData()



watchEffect(() => {
  bread.value = [
    {
      text: t('observations.title'),
      //link: { name: 'admin-logs' }
    },
    {
      text: observation.value ? observation.value.title : '...'
    }
  ]
})

const getCenter = () => {
  if (observation.value.latitude && observation.value.longitude) {
    return [observation.value.latitude, observation.value.longitude]
  }

  return [
    observation.value.city.latitude,
    observation.value.city.longitude
  ]  
}
</script>

<template>
  <div class="help-center-page">
    <Navbar />
    <div class="content-wrapper">
        <VCard
            flat
            class="text-center search-header"
            :style="`background: url(${AppSearchHeaderBg});`"
        >
            <VCardText>
              <h2 class="text-h2 font-weight-medium py-10">
                {{ $t('observations.title') }}
              </h2>

              <p class="text-disabled mb-6">
                {{ $t('landing.observations_description') }}
              </p>
            </VCardText>
        </VCard>


        <div class="py-10 bg-surface">
            <VContainer>
              <Loader
                v-if="!observation"
                class="mt-4"
              />
              <div v-else>
                <VRow class="">

                  <VCol cols="12">
                    <div>
                      <h3 class="text-h3 mb-2">
                        <VChip color="primary" class="mr-2">
                          {{ $t('observations.types.' + observation.type.slug) }}
                        </VChip>
                        {{ observation.title }}
                      </h3>

                      <h5 class="text-h5 mt-2">
                        <div class="d-flex align-center mt-sm-0">
                          <VIcon icon="tabler-calendar" class="me-1" />
                          <span class="text-subtitle-1">{{ moment.utc(observation.occured_at).local().format("Y-MM-DD") }}</span>
                        </div>
                      </h5>

                      <h5 class="text-h5 mt-2">
                        <div class="d-flex align-center mt-sm-0">
                          <VIcon icon="tabler-map-pin" class="me-1" />
                          <span class="text-subtitle-1">{{ observation.country.name }} <span v-if="observation.city">- {{ observation.city.name }}</span></span>
                        </div>
                      </h5>
                    </div>

                    <VDivider class="my-8" />
                    <VRow class="mb-6">
                      <VCol
                        md="6"
                        lg="8"
                        cols="12"
                      >
                        <VCardText v-if="observation.description">
                          <div class="d-flex align-center">
                            <VIcon icon="tabler-info-circle" class="me-1" />
                            <span class="text-subtitle-1">{{ observation.description }}</span>
                          </div>
                        </VCardText>

                        <swiper-container
                          pagination-clickable="true"
                          slides-per-view="5"
                          space-between="50"
                          events-prefix="swiper-"
                          :breakpoints="{
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 40,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 30,
                            },
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                            },
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 10,
                            },
                          }"
                        >
                          <swiper-slide
                            v-for="(swiperMedia, i) in observation.media"
                            :key="i"
                          >
                            <div v-if="swiperMedia.type === 'image'" class="media-container">
                              <VImg
                                :src="swiperMedia.thumb_url"
                                @click="openLightbox(swiperMedia.original_url, 'image')"
                                class="rounded"
                              />
                              <div class="media-overlay">
                                <VIcon icon="tabler-photo" class="me-1" />
                              </div>
                            </div>
                            <div v-else class="media-container">
                              <VImg
                                :src="swiperMedia.thumb_url"
                                @click="openLightbox(swiperMedia.original_url, 'video')"
                                class="rounded"
                              />
                              <div class="media-overlay">
                                <VIcon icon="tabler-video" class="me-1" />
                              </div>
                            </div>
                          </swiper-slide>
                        </swiper-container>

                      </VCol>
                      <VCol
                        cols="12"
                        lg="4"
                        md="6"
                      >
                      <VCard class="pa-0" outlined>
                        <VCardText class="pa-0">
                          <div style="height: 100%; width: 100%; border-radius: 15px; overflow: hidden;">
                            <LMap
                              :zoom="zoom"
                              :center="getCenter()"
                              :use-global-leaflet="false"
                              @ready="onMapReady"
                              style="height: 400px; width: 100%;"
                            >
                              <LMarker :lat-lng="getCenter()" />
                              <LFullScreen :map-ref="mapRef" />
                              <LTileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                layer-type="base"
                                name="OpenStreetMap"
                                :visible="false"
                              />
                              <LTileLayer
                                :url="urlSattelite"
                                layer-type="base"
                                name="Mapbox Satellite"
                                visible
                              />
                              <LTileLayer
                                :url="urlOutdoors"
                                layer-type="base"
                                name="Mapbox Outdoors"
                                :visible="false"
                              />
                              <LControlLayers
                                :collapsed="false"
                                position="topright"
                              />
                            </LMap>
                          </div>
                        </VCardText>
                      </VCard>
                      </VCol>

                    </VRow>
                  
                  </VCol>

                </VRow>
              </div>
            </VContainer>
        </div>
        <div class="page-pricing-free-trial-banner-bg">
          <VContainer>
            <div class="d-flex align-center flex-md-row flex-column position-relative">
              <div class="text-center text-md-start py-4 px-10 px-sm-0">
                <h3 class="text-h3 text-primary mb-2">
                  {{ $t('landing.own_observation') }}
                </h3>
                <p>
                  {{ $t('landing.own_observation_description') }}
                </p>
                <VBtn
                  class="mt-4"
                  :to="{ name: 'observations' }"
                >
                  {{ $t('observations.submit_observation') }}
                </VBtn>
              </div>
              <div class="free-trial-illustrator">
                <VImg
                  :src="laptopGirl"
                  :width="202"
                />
              </div>
            </div>
          </VContainer>
        </div>
    </div>

    <Footer />
    <FsLightbox
      :toggler="toggler"
      :sources="selected"
      :type="selectedType"
      @onClose="toggler = !toggler"
    />
  </div>
</template>


<style lang="scss">
.help-center-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content-wrapper {
    flex: 1; // This makes the content expand to fill the available space
  }

  .search-header {
    background-size: cover !important;
    padding-block-start: 7rem !important;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .help-center-page {
    .v-container {
      padding-inline: 2rem !important;
    }

    .search-header {
      padding: 5rem !important;
    }
  }
}

@media (max-width: 599px) {
  .help-center-page {
    .search-header {
      padding-block-end: 2rem !important;
      padding-block-start: 4rem !important;
      padding-inline: 2rem !important;
    }
  }
}

.media-container {
  position: relative;
}

.media-overlay {
  position: absolute;
  bottom: 2px;
  right: 2px;
  color: white;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
