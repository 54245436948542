<script setup>
import { useI18n } from 'vue-i18n'
import moment from "moment";
import axios from '@/helpers/axios'
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LMarker, LControlLayers } from "@vue-leaflet/vue-leaflet"
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import Breadcrumbs from '@/views/general/Breadcrumbs.vue'
import { watch } from 'vue';

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()
const router = useRouter()

const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlOutdoors = ref('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)

const bread = [
  {
    text: t('analyses.analyses'),
    link: { name: 'analyses' }
  },
  {
    text: t('analyses.new_request')
  }
]

const numberedSteps = [
  {
    title: t('analyses.analysis_type'),
    subtitle: t('analyses.select_analysis_type'),
  },
  {
    title: t('analyses.location'),
    subtitle: t('analyses.select_location'),
  },
  {
    title: t('analyses.info'),
    subtitle: t('analyses.add_info'),
  },
  {
    title: t('analyses.review'),
    subtitle: t('analyses.review_request'),
  },
]

const radioContent = [
  {
    title: t('analyses.soil'),
    desc: t('analyses.soil_description'),
    value: 'soil',
    icon: { icon: 'tabler-seeding', size: '50' },
  },
  {
    title: t('analyses.water'),
    desc: t('analyses.water_description'),
    value: 'water',
    icon: { icon: 'tabler-droplet', size: '50' },
  },
  {
    title: t('analyses.leaf'),
    desc: t('analyses.leaf_description'),
    value: 'leaf',
    icon: { icon: 'tabler-leaf', size: '50' },
  },
]

const currentStep = ref(0)
const isCurrentStepValid = ref(true)

const loading = ref(false)

const zoom = ref(15)
const center = ref([41.295190, -7.746377])

const selectedRadio = ref('soil')
const location = ref([41.295190, -7.746377])
const gettingLocation = ref(true)
const observations = ref(null)
const collect = ref(false)

const refTypeForm = ref()
const refLocationForm = ref()
const refInfoForm = ref()
const refReviewForm = ref()
const map = ref()

const itemsSearch = ref([])
const loadingSearch = ref(false)
const search = ref()
const select = ref(null)

const options = {
  enableHighAccuracy: true,
};

const getLocation = () => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      position => {
        location.value = [position.coords.latitude, position.coords.longitude]
        center.value = [position.coords.latitude, position.coords.longitude]
        gettingLocation.value = false
      },
      err => {
        console.log(`Error getting location: ${err.message}`)
      },
      options
    );
  } else {
    console.log("Geolocation is not supported")
  }
};

getLocation()

const validateTypeForm = () => {
  refTypeForm.value?.validate().then(valid => {
    if (valid.valid) {
      currentStep.value++
      isCurrentStepValid.value = true
    }
    else { isCurrentStepValid.value = false }
  })
}

const validateLocationForm = () => {
  refLocationForm.value?.validate().then(valid => {
    if (valid.valid) {
      currentStep.value++
      isCurrentStepValid.value = true
    }
    else { isCurrentStepValid.value = false }
  })
}

const validateInfoForm = () => {
  refInfoForm.value?.validate().then(valid => {
    if (valid.valid) {
      currentStep.value++
      isCurrentStepValid.value = true
    }
    else { isCurrentStepValid.value = false }
  })
}

const sendRequest = () => {
  loading.value = true
  axios.post('/analyses/new', {
    type: selectedRadio.value,
    latitude: location.value['lat'],
    longitude: location.value['lng'],
    observations: observations.value,
    collect: collect.value
  }).then(response => {
    toast.success(t('analyses.request_submitted'), {
      autoClose: 3000,
    })
    return router.replace({ name: 'analyses-uuid', params: { uuid: response.data.uuid } })
  }).catch(e => {
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
    console.error(e)
  }).finally(() => {
    loading.value = false
  })
}

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const querySelections = (query) => {
  loadingSearch.value = true

  axios.get('/address?address=' + query).then(response => {
    if (response.data.data) {
      itemsSearch.value = response.data.data
    }    
  }).finally(() => {
    loadingSearch.value = false
  })
}

// Watch for changes with a debounce of 500ms
watch(search, debounce(query => {
  if (query && query !== select.value) {
    querySelections(query);
  }
}, 500));

watch(select, (newValue, oldValue) => {
  // Handle changes to the select variable
  center.value = [newValue['latitude'], newValue['longitude']]
  location.value = [newValue['latitude'], newValue['longitude']]
  // You can call your functions or perform actions related to the select change here
});

</script>

<template>
  <div>
    <Breadcrumbs :items="bread"/>

    <VCard :title="t('analyses.request_analysis')">
      <VCardText>
        <!-- 👉 Stepper -->
        <AppStepper
          v-model:current-step="currentStep"
          :items="numberedSteps"
          align="start"
          :is-active-step-valid="isCurrentStepValid"
        />
      </VCardText>

      <VDivider />

      <VCardText>
        <!-- 👉 stepper content -->

        <VWindow
          v-model="currentStep"
          class="disable-tab-transition"
        >
          <VWindowItem>
            <VForm
              ref="refTypeForm"
              @submit.prevent="validateTypeForm"
            >
              <VRow>
                <VCol cols="12">
                  <h6 class="text-h6 font-weight-medium">
                    {{ t('analyses.analysis_type') }}
                  </h6>
                  <p class="mb-0">
                    {{ t('analyses.select_analysis_type') }}
                  </p>
                </VCol>
                
                <VCol cols="12">
                  <CustomRadiosWithIcon
                    v-model:selected-radio="selectedRadio"
                    :radio-content="radioContent"
                    :grid-column="{ sm: '4', cols: '12' }"
                  />
                </VCol>

                <VCol cols="12">
                  <div class="d-flex flex-wrap gap-4 justify-sm-space-between justify-center mt-8">
                    <VBtn
                      color="secondary"
                      variant="tonal"
                      disabled
                    >
                      <VIcon
                        icon="tabler-arrow-left"
                        start
                        class="flip-in-rtl"
                      />
                      {{ t('layout.previous') }}
                    </VBtn>

                    <VBtn type="submit">
                      {{ t('layout.next') }}
                      <VIcon
                        icon="tabler-arrow-right"
                        end
                        class="flip-in-rtl"
                      />
                    </VBtn>
                  </div>
                </VCol>
              </VRow>
            </VForm>
          </VWindowItem>

          <VWindowItem>
            <VForm
              ref="refLocationForm"
              @submit.prevent="validateLocationForm"
            >
              <VRow>
                <VCol cols="12">
                  <h6 class="text-h6 font-weight-medium">
                    {{ t('analyses.location') }}
                  </h6>
                  <p class="mb-0">
                    {{ t('analyses.select_location') }}
                  </p>
                </VCol>

                <VCol cols="12">
                  <VAutocomplete
                    v-model="select"
                    v-model:search="search"
                    :loading="loadingSearch"
                    :items="itemsSearch"
                    item-title="formatted"
                    item-value="location"
                    :placeholder="t('analyses.search_address')"
                    :label="t('analyses.address')"
                    :no-data-text="t('analyses.no_address')"
                    variant="underlined"
                    :menu-props="{ maxHeight: '200px' }"
                  />
                </VCol>

                <VCol cols="12">
                  <div
                    style="height: 400px;"
                  >
                    <LMap
                      ref="map"
                      v-model:zoom="zoom"
                      :center="center"
                      :use-global-leaflet="false"
                    >
                      <LTileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        layer-type="base"
                        name="OpenStreetMap"
                        :visible="false"
                      />
                      <LTileLayer
                        :url="urlSattelite"
                        layer-type="base"
                        name="Mapbox Satellite"
                      />
                      <LTileLayer
                        :url="urlOutdoors"
                        layer-type="base"
                        name="Mapbox Outdoors"
                        :visible="false"
                      />
                      <LControlLayers
                        :collapsed="false"
                        position="topright"
                      />
                      <LMarker
                        draggable
                        :lat-lng="location"
                        @update:lat-lng="(val) => location = val"
                      />
                    </LMap>
                    <h6 class="text-h6 font-weight-medium text-primary text-center mt-2">
                      {{ gettingLocation ? t('analyses.getting_location') : '' }}
                    </h6>
                  </div>
                </VCol>

                <VCol cols="12">
                  <VCheckbox
                    v-model="collect"
                    :rules="[]"
                    :label="t('analyses.request_collect')"
                    required
                  />
                  <VText>
                    {{ t('analyses.collect_description') }}
                  </VText>
                </VCol>

                <VCol cols="12">
                  <div class="d-flex flex-wrap gap-4 justify-sm-space-between justify-center mt-8">
                    <VBtn
                      color="secondary"
                      variant="tonal"
                      @click="currentStep--"
                    >
                      <VIcon
                        icon="tabler-arrow-left"
                        start
                        class="flip-in-rtl"
                      />
                      {{ t('layout.previous') }}
                    </VBtn>

                    <VBtn type="submit">
                      {{ t('layout.next') }}
                      <VIcon
                        icon="tabler-arrow-right"
                        end
                        class="flip-in-rtl"
                      />
                    </VBtn>
                  </div>
                </VCol>
              </VRow>
            </VForm>
          </VWindowItem>

          <VWindowItem>
            <VForm
              ref="refInfoForm"
              @submit.prevent="validateInfoForm"
            >
              <VRow>
                <VCol cols="12">
                  <h6 class="text-h6 font-weight-medium">
                    {{ t('analyses.info') }}
                  </h6>
                  <p class="mb-0">
                    {{ t('analyses.add_info') }}
                  </p>
                </VCol>

                <VCol
                  cols="12"
                >
                  <AppTextarea
                    v-model="observations"
                    :label="t('analyses.observations')"
                    :placeholder="t('analyses.observations_description')"
                  />
                </VCol>

                <VCol cols="12">
                  <div class="d-flex flex-wrap gap-4 justify-sm-space-between justify-center mt-8">
                    <VBtn
                      color="secondary"
                      variant="tonal"
                      @click="currentStep--"
                    >
                      <VIcon
                        icon="tabler-arrow-left"
                        start
                        class="flip-in-rtl"
                      />
                      {{ t('layout.previous') }}
                    </VBtn>

                    <VBtn type="submit">
                      {{ t('layout.next') }}
                      <VIcon
                        icon="tabler-arrow-right"
                        end
                        class="flip-in-rtl"
                      />
                    </VBtn>
                  </div>
                </VCol>
              </VRow>
            </VForm>
          </VWindowItem>

          <VWindowItem>
            <VForm
              ref="refReviewForm"
              @submit.prevent="sendRequest"
            >
              <VRow>
                <VCol cols="12">
                  <h6 class="text-h6 font-weight-medium">
                    {{ t('analyses.review') }}
                  </h6>
                  <p class="mb-0">
                    {{ t('analyses.review_request') }}
                  </p>
                </VCol>

                <VCol
                  cols="12"
                  md="6"
                >
                  <VText>
                    <b>{{ t('analyses.analysis_type') }}:</b> {{ t('analyses.' + selectedRadio) }}
                  </VText>
                  <br>
                  <VText>
                    <b>{{ t('analyses.collect') }}:</b> {{ collect ?  t('layout.yes') : t('layout.no') }}
                  </VText>
                  <br v-if="observations">
                  <VText v-if="observations">
                    <b>{{ t('analyses.observations') }}:</b> {{ observations }}
                  </VText>

                  <VAlert
                    class="mt-6"
                    border="start"
                    color="primary"
                    variant="tonal"
                  >
                    {{ $t('analyses.review_note') }}
                  </VAlert>
                </VCol>

                <VCol
                  cols="12"
                  md="6"
                >
                  <div
                    style="height: 400px;"
                  >
                    <LMap
                      ref="map"
                      zoom="18"
                      :center="location"
                      :use-global-leaflet="false"
                    >
                      <LTileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        layer-type="base"
                        name="OpenStreetMap"
                        :visible="false"
                      />
                      <LTileLayer
                        :url="urlSattelite"
                        layer-type="base"
                        name="Mapbox Satellite"
                      />
                      <LTileLayer
                        :url="urlOutdoors"
                        layer-type="base"
                        name="Mapbox Outdoors"
                        :visible="false"
                      />
                      <LControlLayers
                        :collapsed="false"
                        position="topright"
                      />
                      <LMarker
                        :lat-lng="location"
                      />
                    </LMap>
                  </div>
                </VCol>

                <VCol cols="12">
                  <div class="d-flex flex-wrap gap-4 justify-sm-space-between justify-center mt-8">
                    <VBtn
                      color="secondary"
                      variant="tonal"
                      @click="currentStep--"
                    >
                      <VIcon
                        icon="tabler-arrow-left"
                        start
                        class="flip-in-rtl"
                      />
                      {{ t('layout.previous') }}
                    </VBtn>

                    <VBtn
                      color="success"
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                    >
                      {{ t('layout.send') }}
                    </VBtn>
                  </div>
                </VCol>
              </VRow>
            </VForm>
          </VWindowItem>
        </VWindow>
      </VCardText>
    </VCard>
  </div>
</template>