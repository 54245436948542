<script setup>
import Footer from '@/views/front-pages/front-page-footer.vue';
import Navbar from '@/views/front-pages/front-page-navbar.vue';
import AppSearchHeaderBg from '@images/pages/app-search-header-bg.png';

definePage({
  meta: {
    layout: 'blank',
    public: true,
  }
})

</script>

<template>
    <div class="help-center-page">
        <Navbar />
        <div class="content-wrapper">
            <VCard
                flat
                class="text-center search-header"
                :style="`background: url(${AppSearchHeaderBg});`"
            >
                <VCardText>
                <h3 class="text-h3 font-weight-medium py-10">
                    {{ $t('terms_and_policy.title') }}
                </h3>
                </VCardText>
            </VCard>


            <div class="py-16 bg-surface text-justify">
                <VContainer>
                    <h3 class="text-h3 mb-2">
                        {{ $t('terms_and_policy.terms.title') }}
                    </h3>

                    <p class="mb-10">
                        {{ $t('terms_and_policy.terms.last_update') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.p1') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.terms.sec1.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec1.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec1.p2') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec1.p3') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec1.p4') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec1.p5') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec1.p6') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec1.p7') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.terms.sec2.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec2.p1') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.terms.sec3.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec3.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec3.p2') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec3.p3') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec3.p4') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec3.p5') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec3.p6') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.terms.sec4.title') }}</b>
                    </p>

                    <p v-html="$t('terms_and_policy.terms.sec4.p1')" />

                    <p>
                        <b>{{ $t('terms_and_policy.terms.sec5.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec5.p1') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.terms.sec6.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec6.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec6.p2') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec6.p3') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec6.p4') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec6.p5') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec6.p6') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.terms.sec7.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec7.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec7.p2') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.terms.sec8.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec8.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec8.p2') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec8.p3') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec8.p4') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec8.p5') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec8.p6') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.terms.sec9.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec9.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec9.p2') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.terms.sec9.p3') }}
                    </p>

                    <h3 class="text-h3 mt-10 mb-2">
                        {{ $t('terms_and_policy.policy.title') }}
                    </h3>

                    <p class="mb-10">
                        {{ $t('terms_and_policy.policy.last_update') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.p2') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.policy.sec1.title') }}</b>
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.policy.sec1.subsec11.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec1.subsec11.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec1.subsec11.p2') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.policy.sec1.subsec12.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec1.subsec12.p1') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.policy.sec1.subsec13.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec1.subsec13.p1') }}
                    </p>

                    <ul class="ml-7 mb-5">
                        <li>{{ $t('terms_and_policy.policy.sec1.subsec13.l1') }}</li>
                        <li>{{ $t('terms_and_policy.policy.sec1.subsec13.l2') }}</li>
                        <li>{{ $t('terms_and_policy.policy.sec1.subsec13.l3') }}</li>
                        <li>{{ $t('terms_and_policy.policy.sec1.subsec13.l4') }}</li>
                        <li>{{ $t('terms_and_policy.policy.sec1.subsec13.l5') }}</li>
                    </ul> 

                    <p>
                        <b>{{ $t('terms_and_policy.policy.sec2.title') }}</b>
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.policy.sec2.subsec21.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec2.subsec21.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec2.subsec21.p2') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec2.subsec21.p3') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec2.subsec21.p4') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec2.subsec21.p5') }}
                    </p>

                    <ul class="ml-7 mb-5">
                        <li>{{ $t('terms_and_policy.policy.sec2.subsec21.l1') }}</li>
                        <li>{{ $t('terms_and_policy.policy.sec2.subsec21.l2') }}</li>
                        <li>{{ $t('terms_and_policy.policy.sec2.subsec21.l3') }}</li>
                        <li>{{ $t('terms_and_policy.policy.sec2.subsec21.l4') }}</li>
                        <li>{{ $t('terms_and_policy.policy.sec2.subsec21.l5') }}</li>
                        <li>{{ $t('terms_and_policy.policy.sec2.subsec21.l6') }}</li>
                        <ul class="ml-7">
                            <li>{{ $t('terms_and_policy.policy.sec2.subsec21.l61') }}</li>
                            <li>{{ $t('terms_and_policy.policy.sec2.subsec21.l62') }}</li>
                            <li>{{ $t('terms_and_policy.policy.sec2.subsec21.l63') }}</li>
                            <li>{{ $t('terms_and_policy.policy.sec2.subsec21.l64') }}</li>
                        </ul> 
                    </ul>

                    <p>
                        <b>{{ $t('terms_and_policy.policy.sec2.subsec22.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec2.subsec22.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec2.subsec22.p2') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec2.subsec22.p3') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.policy.sec3.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec3.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec3.p2') }}
                    </p>

                    <p>
                        <b>{{ $t('terms_and_policy.policy.sec4.title') }}</b>
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec4.p1') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec4.p2') }}
                    </p>

                    <p>
                        {{ $t('terms_and_policy.policy.sec4.p3') }}
                    </p>
                    

                </VContainer>
            </div>
        </div>

        <Footer />
    </div>
</template>

<style lang="scss">
.help-center-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content-wrapper {
    flex: 1; // This makes the content expand to fill the available space
  }

  .search-header {
    background-size: cover !important;
    padding-block-start: 7rem !important;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .help-center-page {
    .v-container {
      padding-inline: 2rem !important;
    }

    .search-header {
      padding: 5rem !important;
    }
  }
}

@media (max-width: 599px) {
  .help-center-page {
    .search-header {
      padding-block-end: 2rem !important;
      padding-block-start: 4rem !important;
      padding-inline: 2rem !important;
    }
  }
}
</style>