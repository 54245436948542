<script setup>
import Footer from '@/views/front-pages/front-page-footer.vue'
import Navbar from '@/views/front-pages/front-page-navbar.vue'
import { useTitle } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import axios from '@/helpers/axios'
import Map from '@/views/landing/groups/Map.vue'
import Devices from '@/views/landing/groups/Devices.vue'

definePage({
  meta: {
    layout: 'blank',
    public: true
  }
})

const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const uuid = route.params.uuid
const group = ref(null)

const getData = () => {
  axios.get('/landing/groups/' + uuid).then(response => {
    group.value = response.data
  }).catch(e => {
    if (e.response.status === 404) {
      router.replace('/')
    }
    console.error(e)
  })
}

getData()
</script>

<template>
  <div class="help-center-page">
    <Navbar />
    <div v-if="group">
      <!-- 👉 Knowledge Base -->
      <div class="py-12 mt-12">
        <VContainer>
          <h3 class="text-h3 text-center my-6">
            {{ group.name }}
          </h3>
          
          <Map :devices="group.devices" />
          
          <Devices
            :devices="group.devices"
          />

        </VContainer>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.help-center-page {
  .search-header {
    background-size: cover !important;
    padding-block-start: 7rem !important;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .help-center-page {
    .v-container {
      padding-inline: 2rem !important;
    }

    .search-header {
      padding: 5rem !important;
    }
  }
}

@media (max-width: 599px) {
  .help-center-page {
    .search-header {
      padding-block-end: 2rem !important;
      padding-block-start: 4rem !important;
      padding-inline: 2rem !important;
    }
  }
}
</style>
