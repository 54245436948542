<script setup>
import { useI18n } from 'vue-i18n'
import axios from '@/helpers/axios'
import moment from "moment";
import Loader from '@/views/Loader.vue'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import ClientBioPanel from '@/views/analyses/ClientBioPanel.vue'
import ClientOverview from '@/views/analyses/ClientOverview.vue'
import Breadcrumbs from '@/views/general/Breadcrumbs.vue'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const uuid = route.params.uuid
const client = ref({})
const bread = ref([]);

const getClient = () => {
  axios.get('/analyses-provider/clients/' + uuid).then(response => {
    client.value = response.data
  }).catch(e => {
    console.error(e)
    return router.replace({ name: 'analyses-provider'})
  })
}

getClient()

watchEffect(() => {
  bread.value = [
    {
      text: t('analyses.analyses'),
      link: { name: 'analyses-provider' }
    },
    {
      text: client.value.user ? client.value.user.name : '...'
    }
  ]
})

</script>

<template>
  <div>
    <Breadcrumbs :items="bread"/>
    <!-- 👉 Customer Profile  -->
    <VRow>
      <VCol
        cols="12"
        md="5"
        lg="4"
      >
        <ClientBioPanel v-if="client.user" :customer-data="client" />
      </VCol>
      <VCol
        cols="12"
        md="7"
        lg="8"
      >
        <ClientOverview v-if="client.user" :customer-data="client" />
      </VCol>
    </VRow>
  </div>
</template>
