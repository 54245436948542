<script setup>
import { useI18n } from 'vue-i18n'
import ProviderRequests from '@/views/analyses/ProviderRequests.vue'
import ProviderUsers from '@/views/analyses/ProviderUsers.vue'
import ProviderStats from '@/views/analyses/ProviderStats.vue'
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'analyses'
  },
})

const { t } = useI18n()
const route = useRoute()
const provider = ref({})

const getStats = () => {
  axios.get('/analyses-provider').then(response => {
    provider.value = response.data
  }).catch(e => {
    console.error(e)
  })
}

getStats()
</script>

<template>
  <div>
    <div class="d-flex gap-2 align-center mb-2 flex-wrap">
      <h4 class="text-h4">
        {{ t('analyses.title') + ': ' }} {{ provider.name ? provider.name : '...' }}
      </h4>
    </div>
    
    <ProviderStats class="mb-6" />
    <ProviderRequests class="mb-6" />
    <ProviderUsers />
  </div>
</template>
