<script setup>
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  DataZoomComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'
import * as echarts from 'echarts'
import Loader from '@/views/Loader.vue'
import axios from '@/helpers/axios'
import Breadcrumbs from '@/views/general/Breadcrumbs.vue'
import moment from "moment";

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  DataZoomComponent,
])

const { t } = useI18n()
const route = useRoute()

const uuid = route.params.uuid
const channelUuid = route.params.channel

const device = ref({})
const channel = ref({})
const dates = ref([])
const values = ref([])
const bread = ref([]);

const ready = ref(false)
const loading = ref(false)

const startDate = ref(null)
const endDate = ref(null)

startDate.value = moment().subtract(7, 'days').format('YYYY-MM-DD')
endDate.value = moment().format('YYYY-MM-DD')


axios.get('/devices/' + uuid ).then(response => {
  device.value = response.data
}).catch(e => {
  console.error(e)
}).finally(() => {
  ready.value = true
})

axios.get('/devices/' + uuid + '/' + channelUuid).then(response => {
  channel.value = response.data
}).catch(e => {
  console.error(e)
}).finally(() => {
  ready.value = true
})



const getData = () => {
  loading.value = true
  axios.get('/devices/' + uuid + '/' + channelUuid + '/data', {params: {
    start: startDate.value,
    end: endDate.value,
  }}).then(response => {
    let processedDates = response.data.dates.map(date => moment.utc(date[0]).local().format("Y-MM-DD HH:mm"));
    dates.value = processedDates
    values.value = response.data.values
  }).catch(e => {
    console.error(e)
  }).finally(() => {
    loading.value = false
  })
}

getData()

watch([startDate, endDate], ([newStartDate, newEndDate]) => {
  // Convert start and end dates to moment objects for comparison
  const momentStartDate = moment(newStartDate, 'YYYY-MM-DD');
  const momentEndDate = moment(newEndDate, 'YYYY-MM-DD');

  // Check if startDate is after endDate
  if (momentStartDate.isAfter(momentEndDate)) {
    // If startDate is after endDate, make startDate equal to endDate
    startDate.value = momentEndDate.format('YYYY-MM-DD');
  }

  // Call getData function
  getData();
});

const options = ref({
  tooltip: {
    trigger: 'axis',
  },
  title: {
    left: 'center',
    text: channel.name,
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: dates,
  },
  yAxis: {
    type: 'value',
    boundaryGap: [0, '100%'],
    min:'dataMin', // these are the two minimum values 
    max:'dataMax',
  },
  dataZoom: [
    {
      type: 'inside',
      start: 0,
      end: 100,
    },
    {
      start: 0,
      end: 100,
    },
  ],
  series: [{
    name: channel.name,
    data: values,
    type: 'line',
    symbol: 'none',
    sampling: 'lttb',
    itemStyle: {
      color: 'rgb(255, 70, 131)',
    },
    areaStyle: {
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: 'rgb(255, 158, 68)',
        },
        {
          offset: 1,
          color: 'rgb(255, 70, 131)',
        },
      ]),
    },
  }],
})

watchEffect(() => {
  bread.value = [
    {
      text: t('devices.title'),
      link: { name: 'devices-mine' }
    },
    {
      text: device.value.name ? device.value.name : '...',
      link: { name: 'devices-uuid', params: {uuid: device.value.uuid} }
    },
    {
      text: channel.value.name ? channel.value.name : '...'
    }
  ]
})
</script>

<template>
  <div>
    <Breadcrumbs :items="bread"/>

    <Loader
      v-if="!ready"
    />
    <VCard
      v-else
      class="mb-6"
      :title="channel.name + ' - ' + device.name"
    >
      <VCardText>
        <VForm @submit.prevent="() => {}">
          <VRow>
            <VCol
              cols="12"
              md="6"
            >
              <AppDateTimePicker
                v-model="startDate"
                :label="$t('devices.start_date')"
              />
            </VCol>

            <VCol
              cols="12"
              md="6"
            >
            <AppDateTimePicker
                v-model="endDate"
                :label="$t('devices.end_date')"
              />
            </VCol>
          </VRow>
        </VForm>
      </VCardText>
      <VCardText>
        <Loader
          v-if="loading"
        />
        <VChart
          v-show="!loading"
          style="height: 500px;"
          :option="options"
          autoresize
        />  
      </VCardText>
    </VCard>
  </div>
</template>
