<script setup>
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg'
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { VForm } from 'vuetify/components'
import axios from '@/helpers/axios'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { useI18n } from 'vue-i18n'

const isPasswordVisible = ref(false)
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const errors = ref({
  email: '',
  password: '',
})

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true
  },
})


const refVForm = ref()
const email = ref('')
const password = ref('')
const loading = ref(false)

const flavor = route.query.flavor

let image = null;

switch (flavor) {
  case 'freshwater':
    image = themeConfig.app.logoFreshwater;
    break;
  default:
    image = themeConfig.app.logo;
    break;
}

const login = () => {
  loading.value = true
  axios.post('/auth/login', {
    email: email.value,
    password: password.value,
  }).then(r => {

    if (r.data.result === false) {
      toast.error(t('auth.wrong'), {
        autoClose: 3000,
      })
      return
    }

    const { token, user } = r.data

    useCookie('userData').value = user
    useCookie('accessToken').value = token
    useCookie('userRoles').value = user.roles

    router.replace(route.query.to ? String(route.query.to) : { name: 'dashboard' })

    // Redirect to `to` query if exist or redirect to index route
    //router.replace(route.query.to ? String(route.query.to) : '/dashboard')
  }).catch(e => {
    console.error(e)
  }).finally(() => {
    loading.value = false
  })
}

const onSubmit = () => {
  refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid)
      login()
  })
}
</script>

<template>
  <div class="auth-wrapper d-flex align-center justify-center pa-4">
    <div class="position-relative my-sm-16">
      <!-- 👉 Top shape -->
      <VImg
        :src="authV1TopShape"
        class="auth-v1-top-shape d-none d-sm-block"
      />

      <!-- 👉 Bottom shape -->
      <VImg
        :src="authV1BottomShape"
        class="auth-v1-bottom-shape d-none d-sm-block"
      />

      <!-- 👉 Auth Card -->
      <VCard
        class="auth-card pa-4"
        max-width="448"
      >
        <VCardItem class="justify-center">
          <template #prepend>
            <div class="d-flex">
              <VNodeRenderer :nodes="image" />
            </div>
          </template>

          <VCardTitle v-if="!flavor" class="font-weight-bold text-h5 py-1">
            {{ themeConfig.app.title }}
          </VCardTitle>
        </VCardItem>

        <VCardText class="pt-1">
          <h5 class="text-h5 font-weight-semibold mb-1">
            {{ $t('auth.welcome', { name: themeConfig.app.title }) }} 👋🏻
          </h5>
          <p class="mb-0">
            {{ $t('auth.login_description') }}
          </p>
        </VCardText>

        <VCardText>
          <VForm
            ref="refVForm"
            @submit.prevent="onSubmit"
          >
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <VTextField
                  v-model="email"
                  :label="$t('auth.email')"
                  type="email"
                  :rules="[requiredValidator, emailValidator]"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <VTextField
                  v-model="password"
                  :label="$t('auth.password')"
                  :rules="[requiredValidator]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :error-messages="errors.password"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />

                <!-- remember me checkbox -->
                <div class="d-flex align-center justify-space-between flex-wrap mt-2 mb-4">
                  <RouterLink
                    class="text-primary ms-2 mb-1"
                    :to="{ name: 'forgot-password' }"
                  >
                    {{ $t('auth.forgot_password_login') }}
                  </RouterLink>
                </div>

                <!-- login button -->
                <VBtn
                  block
                  :loading="loading"
                  type="submit"
                >
                  {{ $t('auth.login') }}
                </VBtn>
              </VCol>

              <!-- create account -->
              <VCol
                cols="12"
                class="text-center text-base"
              >
                <span>{{ $t('auth.no_account') }}</span>
                <RouterLink
                  class="text-primary ms-2"
                  :to="{ name: 'register' }"
                >
                  {{ $t('auth.register') }}
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </div>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
