<script setup>
import { useI18n } from 'vue-i18n'
import moment from "moment";
import axios from '@/helpers/axios'
import Breadcrumbs from '@/views/general/Breadcrumbs.vue'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

definePage({
  meta: {
    layout: 'default',
    role: 'admin'
  },
})

const { t } = useI18n()

const bread = [
  {
    text: t('satellite.title'),
    link: { name: 'admin-satellite' }
  },
  {
    text: t('satellite.add_parcel')
  }
]

const users = ref([])
const refVForm = ref()
const selectedUser = ref(null)
const historical = ref(30)
const name = ref('')
const file = ref(null)
const loading = ref(false)

const getUsers = () => {
  axios.get('/admin/maps/users').then(response => {
    users.value = response.data.data
  }).catch(e => {
    console.error(e)
  })
}

getUsers()


const onSubmit = () => {
  refVForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid)
      send()
  })
}

const onFileChange = async e => {
  file.value = e.target.files[0]  
}

const send = () => {
  loading.value = true

  const formdata = new FormData()

  formdata.append('geojson', file.value)
  formdata.append('name', name.value)
  formdata.append('user', selectedUser.value)
  formdata.append('historical', historical.value)


  axios.post('/admin/satellite', formdata).then(response => {
    if (response.data.uuid) {

      toast.success(t('satellite.parcel_created_success'), {
        autoClose: 3000,
      })

      file.value = null
      refVForm.value.reset()
      return
    }
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
  }).catch(e => {
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
    console.error(e)
  }).finally(() => {
    loading.value = false
  })
    
}


</script>

<template>
  <div>
    <Breadcrumbs :items="bread"/>

    <VCard :title="t('satellite.add_parcel')">
      <VCardText>
        <VForm
          ref="refVForm"
          @submit.prevent="onSubmit"
        >
          <VRow>
            <VCol cols="12">
              <AppTextField
                v-model="name"
                :label="$t('satellite.name')"
                :rules="[requiredValidator]"
              />
            </VCol>

            <VCol cols="12">
              <AppTextField
                v-model="historical"
                :label="$t('satellite.number_days')"
                type="number"
                :rules="[requiredValidator]"
              />
            </VCol>

            <VCol cols="12">
              <VSelect
                v-model="selectedUser"
                :items="users"
                :menu-props="{ maxHeight: '400' }"
                item-title="name"
                item-value="uuid"
                :label="$t('satellite.user')"
                :rules="[requiredValidator]"
              />  
            </VCol>

            <VCol cols="12">
              <VFileInput
                ref="fileInputRef"
                accept=".geojson"
                :label="$t('satellite.geojson')"
                :rules="[requiredValidator]"
                @change="onFileChange"
              />
            </VCol>

            <VCol
              cols="12"
              class="d-flex gap-4"
            >
              <VBtn
                type="submit"
                :loading="loading"
                :disabled="loading"
              >
                {{ $t('layout.save') }}
              </VBtn>
            </VCol>
          </VRow>
        </VForm>
      </VCardText>
    </VCard>
  </div>
</template>

<style lang="scss">
#items-list {
  .items-list-actions {
    inline-size: 8rem;
  }

  .items-list-filter {
    inline-size: 12rem;
  }
}
</style>
