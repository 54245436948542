<script setup>
import { useI18n } from 'vue-i18n'
import Comments from '@/views/analyses/Comments.vue'
import Timeline from '@/views/analyses/Timeline.vue'
import AddInvoiceDrawer from '@/views/analyses/AddInvoiceDrawer.vue'
import AddReceiptDrawer from '@/views/analyses/AddReceiptDrawer.vue'
import AddResultDrawer from '@/views/analyses/AddResultDrawer.vue'
import SetPriceDrawer from '@/views/analyses/SetPriceDrawer.vue'
import ResultCard from '@/views/analyses/ResultCard.vue'
import DetailsCard from '@/views/analyses/DetailsCard.vue'
import axios from '@/helpers/axios'
import moment from "moment";
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LMarker, LControlLayers } from "@vue-leaflet/vue-leaflet"
import Loader from '@/views/Loader.vue'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import ConfirmDialog from '@/views/general/ConfirmDialog.vue'
import Breadcrumbs from '@/views/general/Breadcrumbs.vue'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const uuid = route.params.uuid
const analysesRequest = ref({})
const bread = ref([]);

const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlOutdoors = ref('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)

const showInvoiceDrawer = ref(false)
const showPriceDrawer = ref(false)
const showReceiptDrawer = ref(false)
const showResultDrawer = ref(false)
const showConfirmRefuseDialog = ref(false)
const showConfirmFinishDialog = ref(false)
const showConfirmPaidDialog = ref(false)

const getAnalysesRequest = () => {
  axios.get('/analyses-provider/' + uuid).then(response => {
    analysesRequest.value = response.data
  }).catch(e => {
    console.error(e)
    return router.replace({ name: 'analyses-provider'})
  })
}

getAnalysesRequest()

const acceptRequest = () => {
  axios.post('/analyses-provider/' + uuid + '/accept', {}).then(response => {
    toast.success(t('analyses.request_accepted'), {
      autoClose: 3000,
    })
    getAnalysesRequest()
    return
  }).catch(e => {
    console.error(e)
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
  })
}

const refuseRequest = () => {
  axios.post('/analyses-provider/' + uuid + '/refuse', {}).then(response => {
    toast.success(t('analyses.request_refused'), {
      autoClose: 3000,
    })
    showConfirmRefuseDialog.value = false
    getAnalysesRequest()
    return
  }).catch(e => {
    console.error(e)
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
  })
}

const finishRequest = () => {
  axios.post('/analyses-provider/' + uuid + '/finish', {}).then(response => {
    toast.success(t('analyses.request_finished'), {
      autoClose: 3000,
    })
    showConfirmFinishDialog.value = false
    getAnalysesRequest()
    return
  }).catch(e => {
    console.error(e)
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
  })
}

const paidRequest = () => {
  axios.post('/analyses-provider/' + uuid + '/paid', {}).then(response => {
    toast.success(t('analyses.request_set_as_paid'), {
      autoClose: 3000,
    })
    showConfirmPaidDialog.value = false
    getAnalysesRequest()
    return
  }).catch(e => {
    console.error(e)
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
  })
}

watchEffect(() => {
  bread.value = [
    {
      text: t('analyses.analyses'),
      link: { name: 'analyses-provider' }
    },
    {
      text: analysesRequest.value.number ? t('analyses.request') + ' #' + analysesRequest.value.number : '...'
    }
  ]
})
</script>

<template>
  <div>
    <Breadcrumbs :items="bread"/>

    <div class="d-flex justify-space-between align-center flex-wrap gap-y-4 mb-6">
      <div>
        <div class="d-flex gap-2 align-center mb-2 flex-wrap">
          <h4 class="text-h4">
            {{ t('analyses.request') }} #{{ analysesRequest.number }}
          </h4>
          <div class="d-flex gap-x-2">
            <VChip
              v-if="analysesRequest.type === 'water'"
              variant="tonal"
              color="success"
              label
            >
              {{ t('analyses.water') }}
            </VChip>
            <VChip
              v-if="analysesRequest.type === 'soil'"
              variant="tonal"
              color="success"
              label
            >
              {{ t('analyses.soil') }}
            </VChip>
            <VChip
              v-if="analysesRequest.type === 'leaf'"
              variant="tonal"
              color="success"
              label
            >
              {{ t('analyses.leaf') }}
            </VChip>
          </div>
        </div>
        <div>
          <span class="text-body-1">
            {{ moment.utc(analysesRequest.created_at).local().format("Y-MM-DD HH:mm") }}
          </span>
        </div>
      </div>

    </div>

    <VAlert color="primary" class="mb-4" v-if="analysesRequest.finished_at">
      {{ t('analyses.finished_alert') }}
    </VAlert>

    <VAlert color="warning" class="mb-4" v-if="analysesRequest.pending">
      {{ t('analyses.pending_alert') }}
    </VAlert>

    <VAlert color="error" class="mb-4" v-if="analysesRequest.refused_at">
      {{ t('analyses.refused_alert', {date: moment.utc(analysesRequest.refused_at).local().format("Y-MM-DD HH:mm")}) }}
    </VAlert>

    <VRow>
      <VCol cols="12" md="8">
        <!-- 👉 Request Details -->
        <DetailsCard :analyses-request="analysesRequest" />

        <!-- 👉 Result -->
        <ResultCard :analyses-request="analysesRequest" />

        <!-- 👉 Activity -->
        <Timeline :analyses-request="analysesRequest" />

        <!-- 👉 Comments -->
        <Comments v-if="analysesRequest.uuid" :analyses-request="analysesRequest" />

      </VCol>

      <VCol
        cols="12"
        md="4"
      >
        <!-- 👉 Actions -->
        <VCard class="mb-6" v-if="!analysesRequest.refused_at && !analysesRequest.finished_at">
          <Loader
            v-if="!analysesRequest.uuid"
            class="mt-4"
          />
          <VCardText v-else>

            <!-- 👉 Accept/Refuse -->
            <div v-if="analysesRequest.pending">
              <VBtn
                block
                color="primary"
                prepend-icon="tabler-check"
                class="mb-2"
                @click="acceptRequest()"
              >
                {{ t('analyses.accept')}}
              </VBtn>
              <VBtn
                block
                color="error"
                prepend-icon="tabler-x"
                class="mb-2"
                @click="showConfirmRefuseDialog = true"
              >
                {{ t('analyses.refuse')}}
              </VBtn>
            </div>

            <div v-else>
              <div v-if="!analysesRequest.finished_at || !analysesRequest.paid_at || !analysesRequest.result">
                <VBtn
                  block
                  variant="tonal"
                  color="primary"
                  prepend-icon="tabler-currency-euro"
                  class="mb-2"
                  @click="showPriceDrawer = true"
                >
                  {{ t('analyses.set_price')}}
                </VBtn>

                <VBtn
                  v-if="!analysesRequest.paid_at"
                  block
                  variant="tonal"
                  color="primary"
                  prepend-icon="tabler-currency-euro"
                  class="mb-2"
                  @click="showConfirmPaidDialog = true"
                >
                  {{ t('analyses.set_as_paid')}}
                </VBtn>

                <VBtn
                  block
                  variant="tonal"
                  color="primary"
                  prepend-icon="tabler-file-upload"
                  class="mb-2"
                  @click="showInvoiceDrawer = true"
                >
                  {{ analysesRequest.invoice ? t('analyses.replace_invoice') : t('analyses.upload_invoice')}}
                </VBtn>

                <VBtn
                  block
                  variant="tonal"
                  color="primary"
                  prepend-icon="tabler-file-upload"
                  class="mb-2"
                  @click="showReceiptDrawer = true"
                >
                  {{ analysesRequest.receipt ? t('analyses.replace_receipt') : t('analyses.upload_receipt')}}
                </VBtn>

                <VBtn
                  block
                  variant="tonal"
                  color="primary"
                  prepend-icon="tabler-file-upload"
                  class="mb-2"
                  @click="showResultDrawer = true"
                >
                  {{ analysesRequest.result ? t('analyses.replace_result') : t('analyses.upload_result')}}
                </VBtn>
              </div>
              <VBtn
                v-if="!analysesRequest.finished_at && analysesRequest.result && analysesRequest.paid_at"
                block
                color="primary"
                prepend-icon="tabler-check"
                class="mb-2"
                @click="showConfirmFinishDialog = true"
              >
                {{ t('analyses.finish_request')}}
              </VBtn>
              
            </div>
          </VCardText>
        </VCard>

        <!-- 👉 Location -->
        <VCard class="mb-6" :title="t('analyses.location')">
          <VCardText>
            <Loader
              v-if="!analysesRequest.location"
              class="mt-4"
            />
            <div
              v-else
              style="height: 200px;"
            >
              <LMap
                ref="map"
                zoom="17"
                :center="[analysesRequest.location.latitude, analysesRequest.location.longitude]"
                :use-global-leaflet="false"
                :options="{scrollWheelZoom:false}"
              >
                <LTileLayer
                  :url="urlSattelite"
                  layer-type="base"
                  name="Mapbox Satellite"
                />
                <LMarker
                  :lat-lng="[analysesRequest.location.latitude, analysesRequest.location.longitude]"
                />
              </LMap>
            </div>
          </VCardText>
        </VCard>

      </VCol>
    </VRow>
   
    <!-- Add invoice -->
    <AddInvoiceDrawer
      :is-drawer-open="showInvoiceDrawer"
      :analyses-request="analysesRequest"
      @update:is-drawer-open="(val) => showInvoiceDrawer = val"
      @submit="getAnalysesRequest"
    />

    <!-- Add invoice -->
    <AddReceiptDrawer
      :is-drawer-open="showReceiptDrawer"
      :analyses-request="analysesRequest"
      @update:is-drawer-open="(val) => showReceiptDrawer = val"
      @submit="getAnalysesRequest"
    />

    <!-- Add invoice -->
    <AddResultDrawer
      :is-drawer-open="showResultDrawer"
      :analyses-request="analysesRequest"
      @update:is-drawer-open="(val) => showResultDrawer = val"
      @submit="getAnalysesRequest"
    />
    
    <!--Confirm refuse request -->
    <ConfirmDialog
      :is-dialog-open="showConfirmRefuseDialog"
      :title="$t('analyses.refuse_request')"
      :text="$t('analyses.refuse_request_confirm')"
      color="error"
      @update:is-dialog-open="(val) => showConfirmRefuseDialog = val"
      @submit="refuseRequest"
    />

    <!--Confirm finish -->
    <ConfirmDialog
      :is-dialog-open="showConfirmFinishDialog"
      :title="$t('analyses.finish_request')"
      :text="$t('analyses.finish_request_confirm')"
      color="primary"
      @update:is-dialog-open="(val) => showConfirmFinishDialog = val"
      @submit="finishRequest"
    />

    <!--Confirm paid -->
    <ConfirmDialog
      :is-dialog-open="showConfirmPaidDialog"
      :title="$t('analyses.set_as_paid')"
      :text="$t('analyses.set_as_paid_confirm')"
      color="primary"
      @update:is-dialog-open="(val) => showConfirmPaidDialog = val"
      @submit="paidRequest"
    />

    <!--Set price drawer -->
    <SetPriceDrawer
      :is-drawer-open="showPriceDrawer"
      :analyses-request="analysesRequest"
      @update:is-drawer-open="(val) => showPriceDrawer = val"
      @submit="getAnalysesRequest"
    />
    
  </div>
</template>
