<script setup>
import { useI18n } from 'vue-i18n'
import Comments from '@/views/analyses/Comments.vue'
import Timeline from '@/views/analyses/Timeline.vue'
import axios from '@/helpers/axios'
import moment from "moment";
import "leaflet/dist/leaflet.css"
import { LMap, LTileLayer, LMarker, LControlLayers } from "@vue-leaflet/vue-leaflet"
import Loader from '@/views/Loader.vue'
import ResultCard from '@/views/analyses/ResultCard.vue'
import DetailsCard from '@/views/analyses/DetailsCard.vue'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const uuid = route.params.uuid
const analysesRequest = ref({})

const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlOutdoors = ref('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)


const getAnalysesRequest = () => {
  axios.get('/analyses/' + uuid).then(response => {
    analysesRequest.value = response.data
  }).catch(e => {
    console.error(e)
    return router.replace({ name: 'analyses'})
  })
}

getAnalysesRequest()

const downloadFile = (url) => {
  window.location.href = url
}
</script>

<template>
  <div>
    <div class="d-flex justify-space-between align-center flex-wrap gap-y-4 mb-6">
      <div>
        <div class="d-flex gap-2 align-center mb-2 flex-wrap">
          <h4 class="text-h4">
            {{ t('analyses.request') }} #{{ analysesRequest.number }}
          </h4>
          <div class="d-flex gap-x-2">
            <VChip
              v-if="analysesRequest.paid"
              variant="tonal"
              color="success"
              label
            >
              {{ t('analyses.paid') }}
            </VChip>
            <VChip
              v-else
              variant="tonal"
              color="error"
              label
            >
              {{ t('analyses.unpaid') }}
            </VChip>
            <VChip
              v-if="analysesRequest.collect"
              variant="tonal"
              color="warning"
              label
            >
              {{ t('analyses.collect_requested') }}
            </VChip>
            <VChip
              v-if="analysesRequest.finished_at"
              variant="tonal"
              color="info"
              label
            >
              {{ t('analyses.finished') }}
            </VChip>
          </div>
        </div>
        <div>
          <span class="text-body-1">
            {{ moment.utc(analysesRequest.created_at).local().format("Y-MM-DD HH:mm") }}
          </span>
        </div>
      </div>

    </div>

    <VAlert color="primary" class="mb-4" v-if="analysesRequest.finished_at">
      {{ t('analyses.finished_alert') }}
    </VAlert>

    <VAlert color="warning" class="mb-4" v-if="analysesRequest.pending">
      {{ t('analyses.pending_alert_user') }}
    </VAlert>

    <VAlert color="error" class="mb-4" v-if="analysesRequest.refused_at">
      {{ t('analyses.refused_alert', {date: moment.utc(analysesRequest.refused_at).local().format("Y-MM-DD HH:mm")}) }}
    </VAlert>

    <VRow>
      <VCol cols="12" md="8">
        <!-- 👉 Request Details -->
        <DetailsCard :analyses-request="analysesRequest" />        

        <!-- 👉 Result -->
        <ResultCard :analyses-request="analysesRequest" />

        <!-- 👉 Activity -->
        <Timeline :analyses-request="analysesRequest" />

        <!-- 👉 Comments -->
        <Comments v-if="analysesRequest.uuid" :analyses-request="analysesRequest" />
      </VCol>

      <VCol
        cols="12"
        md="4"
      >
        <!-- 👉 Actions -->
        <VCard class="mb-6" v-if="analysesRequest.receipt || analysesRequest.invoice">
          <VCardText>
            <VBtn
              v-if="analysesRequest.receipt"
              block
              variant="tonal"
              color="secondary"
              prepend-icon="tabler-download"
              class="mb-2"
              @click="downloadFile(analysesRequest.receipt.url)"
            >
              {{ t('analyses.download_receipt')}}
            </VBtn>

            <VBtn
              v-if="analysesRequest.invoice"
              block
              variant="tonal"
              color="secondary"
              prepend-icon="tabler-download"
              class="mb-2"
              @click="downloadFile(analysesRequest.invoice.url)"
            >
              {{ t('analyses.download_invoice')}}
            </VBtn>
          </VCardText>
        </VCard>

        <!-- 👉 Location -->
        <VCard class="mb-6" :title="t('analyses.location')">
          <VCardText>
            <Loader
              v-if="!analysesRequest.location"
              class="mt-4"
            />
            <div
              v-else
            >
              <a :href="'https://www.google.com/maps/search/?api=1&query=' + analysesRequest.location.latitude + ',' + analysesRequest.location.longitude" target="_blank">
                {{ t('analyses.open_google_maps') }}
              </a>
              <div style="height: 200px;" class="mt-2">
                <LMap
                  ref="map"
                  zoom="17"
                  :center="[analysesRequest.location.latitude, analysesRequest.location.longitude]"
                  :use-global-leaflet="false"
                  :options="{scrollWheelZoom:false}"
                >
                  <LTileLayer
                    :url="urlSattelite"
                    layer-type="base"
                    name="Mapbox Satellite"
                  />
                  <LMarker
                    :lat-lng="[analysesRequest.location.latitude, analysesRequest.location.longitude]"
                  />
                </LMap>
              </div>
            </div>
          </VCardText>
        </VCard>

      </VCol>
    </VRow>
   
    
  </div>
</template>
