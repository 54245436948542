<script setup>
import axios from '@/helpers/axios'
import Loader from '@/views/Loader.vue'
import Footer from '@/views/front-pages/front-page-footer.vue'
import Navbar from '@/views/front-pages/front-page-navbar.vue'
import { LControlLayers, LMap, LMarker, LPopup, LTileLayer } from "@vue-leaflet/vue-leaflet"
import { useTitle } from '@vueuse/core'
import L from 'leaflet'
import "leaflet/dist/leaflet.css"
import { useI18n } from 'vue-i18n'
import AppSearchHeaderBg from '@images/pages/app-search-header-bg.png'

definePage({
  meta: {
    layout: 'blank',
    public: true
  }
})

const router = useRouter()
const { t } = useI18n()

useTitle(t('landing.stations') + ' - Kropie')

const devices = ref(null)
const locations = ref([])

const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlOutdoors = ref('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const center = ref([40, -7.746377])
const zoom = ref(7)


const updateData = async () => {
  axios.get('/landing/stations').then(response => {
    devices.value = response.data.data
  }).catch(e => {
    console.error(e)
  })
}

updateData()

const bounds = computed(() => {
  if (devices === null) {
    return null
  }

  devices.value.forEach(device => {
    locations.value.push([device.last_location.latitude, device.last_location.longitude])
  })

  const bounds =  L.latLngBounds(locations.value)
  
  return [
    [bounds._southWest.lat, bounds._southWest.lng],
    [bounds._northEast.lat, bounds._northEast.lng],
  ]
})

</script>

<template>
  <div class="help-center-page">
    <Navbar />
    <div class="content-wrapper">
        <VCard
            flat
            class="text-center search-header"
            :style="`background: url(${AppSearchHeaderBg});`"
        >
            <VCardText>
              <h3 class="text-h3 font-weight-medium py-10">
                {{ $t('landing.stations') }}
              </h3>

              <p class="text-disabled mb-6">
                {{ $t('landing.stations_description') }}
              </p>
            </VCardText>
        </VCard>


        <div class="py-16 bg-surface">
            <VContainer>
              <Loader
                v-if="devices === null"
                class="mt-4"
              />
              <LMap
                  v-else
                  ref="map"
                  :options="{scrollWheelZoom:false}"
                  :center="center"
                  :zoom="zoom"
                  :bounds="bounds"
                  :padding="[50, 50]"
                  :use-global-leaflet="false"
                  style="height: 80vh;"
                >
                  <LTileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    layer-type="base"
                    name="OpenStreetMap"
                    :visible="false"
                  />
                  <LTileLayer
                    :url="urlSattelite"
                    layer-type="base"
                    name="Mapbox Satellite"
                    :visible="false"
                  />
                  <LTileLayer
                    :url="urlOutdoors"
                    layer-type="base"
                    name="Mapbox Outdoors"
                    visible
                  />
                  <LControlLayers
                    :collapsed="false"
                    position="topright"
                  />
                  <LMarker
                    v-for="device in devices"
                    :key="device.uuid"
                    :lat-lng="[device.last_location.latitude, device.last_location.longitude]"
                    :name="device.name"
                  >
                    <LPopup>
                      <RouterLink
                        :to="{name: 'stations-uuid', params: {uuid: device.uuid}}"
                      >
                        {{ device.name }}
                      </RouterLink>
                    </LPopup>
                  </LMarker>
                </LMap>
            </VContainer>
        </div>
        <div class="page-pricing-free-trial-banner-bg">
          <VContainer>
            <div class="d-flex align-center flex-md-row flex-column position-relative">
              <div class="text-center text-md-start py-4 px-10 px-sm-0">
                <h3 class="text-h3 text-primary mb-2">
                  {{ $t('landing.own_station') }}
                </h3>
                <p>
                  {{ $t('landing.own_station_description') }}
                </p>
                <VBtn
                  class="mt-4"
                  to="/#contact-us"
                >
                  {{ $t('landing.contact_us') }}
                </VBtn>
              </div>
              <div class="free-trial-illustrator">
                <VImg
                  :src="laptopGirl"
                  :width="202"
                />
              </div>
            </div>
          </VContainer>
        </div>
    </div>

    <Footer />
  </div>
</template>


<style lang="scss">
.help-center-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content-wrapper {
    flex: 1; // This makes the content expand to fill the available space
  }

  .search-header {
    background-size: cover !important;
    padding-block-start: 7rem !important;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .help-center-page {
    .v-container {
      padding-inline: 2rem !important;
    }

    .search-header {
      padding: 5rem !important;
    }
  }
}

@media (max-width: 599px) {
  .help-center-page {
    .search-header {
      padding-block-end: 2rem !important;
      padding-block-start: 4rem !important;
      padding-inline: 2rem !important;
    }
  }
}
</style>
