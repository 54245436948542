<script setup>
import { LControlLayers, LGeoJson, LMap, LTileLayer, LImageOverlay, LMarker } from "@vue-leaflet/vue-leaflet"
import "leaflet/dist/leaflet.css"
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import LFullScreen from "vue-leaflet-fullscreen"
import "vue-leaflet-fullscreen/dist/style.css"
import axios from '@/helpers/axios'
import Loader from '@/views/Loader.vue'
import moment from "moment";
import Breadcrumbs from '@/views/general/Breadcrumbs.vue'
import { register } from 'swiper/element/bundle'
import FsLightbox from "fslightbox-vue/v3";
import { truncate } from 'lodash';
import { VCardText } from "vuetify/lib/components/index.mjs"

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const accessToken = ref('pk.eyJ1IjoicmVuYXRvbXMiLCJhIjoiY2pzcDNpaDdlMHFvcTQ5cWx3eXJlMDZ2eiJ9.3GsYRV5ahGFqCpb01u7qFw')
const urlOutdoors = ref('https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)
const urlSattelite = ref('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken.value)

const { t } = useI18n()
const route = useRoute()
const uuid = route.params.uuid
const zoom = ref(17)
const observation = ref(null)
const mapRef = ref()
const toggler = ref(false)
const selected = ref(null)
const selectedType = ref('image')

const bread = ref([
  {
    text: t('observations.title'),
    link: { name: 'observations' }
  },
  {
    text: '...'
  }
])

function onMapReady(m) {
  mapRef.value = m
}

const getData = () => {
  axios.get('/observation/' + uuid).then(response => {
    observation.value = response.data
  }).catch(e => {
    console.error(e)
  })
}

getData()


watch(observation, () => {
  bread.value = [
    {
      text: t('observations.title'),
      link: { name: 'observations' }
    },
    {
      text: truncate(observation.value.title, { length: 20, omission: '...' })
    }
  ]
})

const openLightbox = (val, type) => {
  selected.value = [val]
  toggler.value = !toggler.value
  selectedType.value = type
}

register()

const getCenter = () => {
  if (observation.value.latitude && observation.value.longitude) {
    return [observation.value.latitude, observation.value.longitude]
  }

  return [
    observation.value.city.latitude,
    observation.value.city.longitude
  ]  
}
</script>

<template>
  <div>
    <Breadcrumbs :items="bread"/>

    <Loader
      v-if="observation === null"
      class="mt-4"
    />
    <VRow v-else class="mb-6">

      <VCol
        md="6"
        lg="8"
        cols="12"
      >
        <VCard
        >
          <VCardTitle class="mt-2">
            <div class="d-flex justify-space-between flex-wrap flex-column flex-sm-row">
              <div class="d-flex align-center mt-2 mt-sm-0">
                {{ observation.title }}
              </div>
              <div class="d-flex align-center mt-2 mt-sm-0">
                <VChip color="primary">
                  {{ $t('observations.types.' + observation.type.slug) }}
                </VChip>
              </div>
            </div>
          </VCardTitle>

          <VCardText>
            <div class="d-flex align-center mt-2 mt-sm-0">
              <VIcon icon="tabler-map-pin" class="me-1" />
              <span class="text-subtitle-1">{{ observation.country.name }} <span v-if="observation.city">- {{ observation.city.name }}</span></span>
            </div>
            <div class="d-flex align-center mt-2 mt-sm-0">
              <VIcon icon="tabler-calendar" class="me-1" />
              <span class="text-subtitle-1">{{ moment.utc(observation.occured_at).local().format('Y-MM-DD') }}</span>
            </div>
          </VCardText>

          <VCardText v-if="observation.description">
            <div class="d-flex align-center mt-2 mt-sm-0">
              <VIcon icon="tabler-info-circle" class="me-1" />
              <span class="text-subtitle-1">{{ observation.description }}</span>
            </div>
          </VCardText>

          <VCardText>
            <swiper-container
              pagination-clickable="true"
              slides-per-view="5"
              space-between="50"
              events-prefix="swiper-"
              :breakpoints="{
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
              }"
            >
              <swiper-slide
                v-for="(swiperMedia, i) in observation.media"
                :key="i"
              >
                <div v-if="swiperMedia.type === 'image'" class="media-container">
                  <VImg
                    :src="swiperMedia.thumb_url"
                    @click="openLightbox(swiperMedia.original_url, 'image')"
                    class="rounded"
                  />
                  <div class="media-overlay">
                    <VIcon icon="tabler-photo" class="me-1" />
                  </div>
                </div>
                <div v-else class="media-container">
                  <VImg
                    :src="swiperMedia.thumb_url"
                    @click="openLightbox(swiperMedia.original_url, 'video')"
                    class="rounded"
                  />
                  <div class="media-overlay">
                    <VIcon icon="tabler-video" class="me-1" />
                  </div>
                </div>
              </swiper-slide>
            </swiper-container>
          </VCardText>


          <VCardText class="d-flex justify-space-between align-center flex-wrap pb-4">
            <div class="d-flex align-center">
              <VAvatar size="34" :image="observation.user.avatar_thumbnail" />
              <span class="ms-2 font-weight-medium">{{ observation.user.name }}</span>
            </div>

            <div class="d-flex align-center">
              <IconBtn icon="tabler-eye" class="me-1" />
              <span class="text-subtitle-2 mt-1">{{ observation.views }}</span>
            </div>
          </VCardText>
        </VCard>
      </VCol>

      <VCol
        cols="12"
        lg="4"
        md="6"
      >
        <VCard class="pa-0" outlined>
          <VCardText class="pa-0">
            <div style="height: 100%; width: 100%; border-radius: 15px; overflow: hidden;">
              <LMap
                :zoom="zoom"
                :center="getCenter()"
                :use-global-leaflet="false"
                @ready="onMapReady"
                style="height: 400px; width: 100%;"
              >
                <LMarker :lat-lng="getCenter()" />
                <LFullScreen :map-ref="mapRef" />
                <LTileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  layer-type="base"
                  name="OpenStreetMap"
                  :visible="false"
                />
                <LTileLayer
                  :url="urlSattelite"
                  layer-type="base"
                  name="Mapbox Satellite"
                  visible
                />
                <LTileLayer
                  :url="urlOutdoors"
                  layer-type="base"
                  name="Mapbox Outdoors"
                  :visible="false"
                />
                <LControlLayers
                  :collapsed="false"
                  position="topright"
                />
              </LMap>
            </div>
          </VCardText>
        </VCard>
      </VCol>
      
    </VRow>
      
    <FsLightbox
      :toggler="toggler"
      :sources="selected"
      :type="selectedType"
      :scrollZoom="true"
      @onClose="toggler = !toggler"
    />
  </div>
</template>


<style scoped>
.media-container {
  position: relative;
}

.media-overlay {
  position: absolute;
  bottom: 2px;
  right: 2px;
  color: white;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>