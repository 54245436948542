<script setup>
import { useI18n } from 'vue-i18n'
import moment from "moment";
import axios from '@/helpers/axios'

definePage({
  meta: {
    layout: 'default',
    role: 'user'
  },
})

const { t } = useI18n()

const searchQuery = ref('')
const rowPerPage = ref(10)
const currentPage = ref(1)
const totalPage = ref(1)
const totalItems = ref(0)
const items = ref([])
const stats = ref()

watchEffect(() => {
  axios.get('/devices', {
    params: {
      q: searchQuery.value,
      perPage: rowPerPage.value,
      currentPage: currentPage.value,
      type: 'mine',
    },
  }).then(response => {
    items.value = response.data.data
    totalPage.value = response.data.meta.last_page
    totalItems.value = response.data.meta.total
  }).catch(e => {
    console.error(e)
  })
})

watchEffect(() => {
  if (currentPage.value > totalPage.value)
    currentPage.value = totalPage.value
})


// 👉 Computing pagination data
const paginationData = computed(() => {
  const firstIndex = items.value.length ? (currentPage.value - 1) * rowPerPage.value + 1 : 0
  const lastIndex = items.value.length + (currentPage.value - 1) * rowPerPage.value
  
  return t('tables.showing', { firstIndex, lastIndex, totalItems: totalItems.value })
})

const getStats = () => {
  axios.get('/devices/stats').then(response => {
    stats.value = response.data
  }).catch(e => {
    console.error(e)
  })
}

getStats()

const resolveStatusColor = stat => {
  switch (stat) {
  case 'online':
    return 'primary'
  case 'delayed':
    return 'warning'
  case 'hibernated':
    return 'error'
  default:
    return ''
  }
}

const resolveStatusIcon = stat => {
  switch (stat) {
  case 'online':
    return 'tabler-wifi'
  case 'delayed':
    return 'tabler-clock-hour-4'
  case 'hibernated':
    return 'tabler-player-pause-filled'
  default:
    return ''
  }
}
</script>

<template>
  <div>
    <VCard class="mb-6">
      <VCardText>
        <VRow>
          <VCol
            cols="12"
            sm="6"
            md="3"
            class="px-6"
          >
            <div
              class="d-flex justify-space-between"
              :class="$vuetify.display.xs
                ? 'product-widget'
                : $vuetify.display.sm
                  ? id < 2 ? 'product-widget' : ''
                  : ''"
            >
              <div class="d-flex flex-column gap-y-1">
                <div class="text-body-1 font-weight-medium text-capitalize">
                  {{ $t('devices.total') }}
                </div>

                <h4 class="text-h4 my-1">
                  {{ stats ? stats.total : 0 }}
                </h4>
              </div>

              <VAvatar
                variant="tonal"
                rounded
                size="38"
              >
                <VIcon
                  icon="tabler-box"
                  size="28"
                />
              </VAvatar>
            </div>
          </VCol>
          <VDivider
            vertical
            inset
            length="95"
          />
          <VCol
            cols="12"
            sm="6"
            md="3"
            class="px-6"
          >
            <div
              class="d-flex justify-space-between"
              :class="$vuetify.display.xs
                ? 'product-widget'
                : $vuetify.display.sm
                  ? id < 2 ? 'product-widget' : ''
                  : ''"
            >
              <div class="d-flex flex-column gap-y-1">
                <div class="text-body-1 font-weight-medium text-capitalize">
                  {{ $t('devices.online') }}
                </div>

                <h4 class="text-h4 my-1">
                  {{ stats ? stats.online : 0 }}
                </h4>
              </div>

              <VAvatar
                variant="tonal"
                color="primary"
                rounded
                size="38"
              >
                <VIcon
                  icon="tabler-wifi"
                  size="28"
                />
              </VAvatar>
            </div>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
    <VCard
      v-if="items"
      id="items-list"
      :title="$t('devices.my_devices')"
    >
      <VCardText class="d-flex align-center flex-wrap gap-4">
        <!-- 👉 Rows per page -->
        <div
          class="d-flex align-center"
          style="width: 150px;"
        >
          <span class="text-no-wrap me-3">{{ $t('tables.show') }}</span>
          <VSelect
            v-model="rowPerPage"
            density="compact"
            :items="[10, 20, 30, 50]"
          />
        </div>

        <VSpacer />

        <div class="d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div class="items-list-filter">
            <VTextField
              v-model="searchQuery"
              :placeholder="$t('devices.search_device')"
              density="compact"
            />
          </div>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION Table -->
      <VTable class="text-no-wrap items-list-table">
        <!-- 👉 Table head -->
        <thead class="text-uppercase">
          <tr>
            <th scope="col">
              {{ $t('devices.name') }}
            </th>

            <th scope="col">
              {{ $t('devices.model') }}
            </th>

            <th scope="col">
              {{ $t('devices.location') }}
            </th>

            <th scope="col">
              {{ $t('devices.last_data') }}
            </th>

            <th scope="col" />
          </tr>
        </thead>

        <!-- 👉 Table Body -->
        <tbody>
          <tr
            v-for="device in items"
            :key="device.uuid"
            style="height: 3.75rem;"
          >
            <!-- 👉 Name -->
            <td>
              <RouterLink :to="{ name: 'devices-uuid', params: { uuid: device.uuid } }">
                {{ device.name }}
              </RouterLink>
            </td>

            <!-- 👉 Model -->
            <td>
              {{ device.model }}
            </td>

            <!-- 👉 Model -->
            <td>
              {{ device.location }}
            </td>

            <!-- 👉 Model -->
            <td>
              <VAvatar
                  v-if="device.status"
                  class="mr-2"
                  :color="resolveStatusColor(device.status)"
                  :icon="resolveStatusIcon(device.status)"
                  size="small"
                />
              {{ device.last_data ? moment.utc(device.last_data).local().format("Y-MM-DD HH:mm") : $t('devices.no_data') }}
            </td>

            <!-- 👉 Actions -->
            <td style="width: 8rem;">
              <VBtn
                icon
                variant="text"
                color="default"
                size="x-small"
                :to="{ name: 'devices-uuid', params: { uuid: device.uuid } }"
              >
                <VIcon
                  :size="22"
                  icon="tabler-eye"
                />
              </VBtn>
            </td>
          </tr>
        </tbody>

        <!-- 👉 table footer  -->
        <tfoot v-show="!items.length">
          <tr>
            <td
              colspan="8"
              class="text-center text-body-1"
            >
              {{ $t('devices.no_devices') }}
            </td>
          </tr>
        </tfoot>
      </VTable>
      <!-- !SECTION -->

      <VDivider />

      <!-- SECTION Pagination -->
      <VCardText class="d-flex align-center flex-wrap gap-4 py-3">
        <!-- 👉 Pagination meta -->
        <span class="text-sm text-disabled">
          {{ paginationData }}
        </span>

        <VSpacer />

        <!-- 👉 Pagination -->
        <VPagination
          v-model="currentPage"
          size="small"
          :total-visible="5"
          :length="totalPage"
        />
      </VCardText>
      <!-- !SECTION -->
    </VCard>
  </div>
</template>

<style lang="scss">
#items-list {
  .items-list-actions {
    inline-size: 8rem;
  }

  .items-list-filter {
    inline-size: 12rem;
  }
}
</style>
